import {Outlet} from "react-router-dom";
import {useContext} from "react";
import {VerticalNavbar} from "../globalComponents/VerticalNavbar";
import {Theme} from "../themes/types";
import {UserContext} from "../infrastructure/UserContext";
import {ThemeContext} from "../infrastructure/ThemeContext";
import {UserType} from "../userAggregate/UserTypeEnum";
import {HeaderNavbarContainer} from "../globalComponents/headerNavBar/HeaderNavbarContainer";

export const PrivateRoutes = () => {
    const {user} = useContext(UserContext)
    const {userType} = user;
    const {toggleTheme} = useContext(ThemeContext);
    
    
    const selectedTheme = (userType === 1) ? Theme.TrueHouse : Theme.BeLit
    toggleTheme(selectedTheme)
    return (
        <>
            {(() => {

                switch (userType) {
                    case UserType.Internal:
                        return (
                            <div className="bg-primary min-h-screen">
                                <Outlet />
                            </div>
                        )
                    case UserType.External:
                        return (
                            <div className="bg-primary min-h-screen">
                                <Outlet />
                            </div>
                        )
                    case UserType.Admin:
                        return (
                            <div className="flex row min-h-screen">
                                <VerticalNavbar dataTestId="admin" />
                                <div className="w-full">
                                    <HeaderNavbarContainer/>
                                    <Outlet />
                                </div>
                            </div>
                        )
                    default:
                        return <></>
                }
            })()}
        </>
    )
}