import {OrderEntryEmailAssociation} from "./OrderEntryEmailAssociation";
import {ALL_ORDERS} from "../ui/orderBatch/batchEmails/AllOrdersText";
import {OrderEntryEmailAssociationsMode} from "./OrderEntryEmailAssociationsMode";

export class OrderEntryEmail {
    private _orderEntryBatchId: string;
    private _id: string;
    private _title: string;
    private _body: string;
    private _attachments: string[];
    protected _orderEntryAssociations: OrderEntryEmailAssociation[];
    private _areAllOrdersSelected: boolean;
    private _isActionsMenuOpen: boolean;
    private _associationTagsMode: OrderEntryEmailAssociationsMode;
    private _isConfirmationModalOpen: boolean;

    constructor(orderEntryBatchId: string, id: string, title: string, body: string, attachments: string[], orderEntryEmailAssociations: OrderEntryEmailAssociation[] = [], associationTagsMode: OrderEntryEmailAssociationsMode = OrderEntryEmailAssociationsMode.AllOrders, areAllOrdersSelected: boolean = false, actionsMenu: boolean = false, isConfirmationModalOpen: boolean = false) {
        this._orderEntryBatchId = orderEntryBatchId;
        this._id = id;
        this._title = title;
        this._body = body;
        this._attachments = attachments;
        this._orderEntryAssociations = this.addAllOrdersTextToOrderEntryFormNames(orderEntryEmailAssociations);
        this._areAllOrdersSelected = areAllOrdersSelected;
        this._isActionsMenuOpen = actionsMenu;
        this._isConfirmationModalOpen = isConfirmationModalOpen;
        this._associationTagsMode = associationTagsMode;
        this.setAllOrdersToSelectedStatusIfAllOrdersAreSelected();
    }

    private clone() {
        return new OrderEntryEmail(this._orderEntryBatchId, this._id, this._title, this._body, [...this._attachments], [...this._orderEntryAssociations], this._associationTagsMode, this._areAllOrdersSelected, this._isActionsMenuOpen, this._isConfirmationModalOpen);
    }

    private setAllOrdersToSelectedStatusIfAllOrdersAreSelected() {
        if (this._areAllOrdersSelected) {
            this._orderEntryAssociations.forEach(x => {
                x.isSelected = true;
            });
        }
    }

    private addAllOrdersTextToOrderEntryFormNames(orderEntryEmailAssociations: OrderEntryEmailAssociation[]) {
        if (!orderEntryEmailAssociations.some(x => x.displayText === ALL_ORDERS)) {
            orderEntryEmailAssociations.unshift(new OrderEntryEmailAssociation("", this._id, "", ALL_ORDERS, false));
        }
        return orderEntryEmailAssociations;
    }

    //region state methods

    public setAreAllOrdersSelected(value: boolean) {
        const result = this.clone();
        result._areAllOrdersSelected = value;
        this._orderEntryAssociations.forEach(x => {
            x.isSelected = value;
        });
        if (value) {
            result._associationTagsMode = OrderEntryEmailAssociationsMode.AllOrders;
        } else {
            result._associationTagsMode = OrderEntryEmailAssociationsMode.Manual;
        }
        return result;
    }

    public updateOrderEntryAssociation(orderEntryAssociation: OrderEntryEmailAssociation): OrderEntryEmail {
        const result = this.clone();

        const indexOfItemToUpdate = result._orderEntryAssociations.findIndex(x => x.orderEntryViewId === orderEntryAssociation.orderEntryViewId);

        result._orderEntryAssociations.splice(indexOfItemToUpdate, 1, orderEntryAssociation);

        return result;
    }

    public openActionMenu(): OrderEntryEmail {
        const result = this.clone();
        result._isActionsMenuOpen = true;
        return result;
    }

    public closeActionMenu(): OrderEntryEmail {
        const result = this.clone();
        result._isActionsMenuOpen = false
        return result;
    }

    public setIsConfirmationModalOpen(isOpen: boolean) {
        const result = this.clone();
        result._isConfirmationModalOpen = isOpen;
        return result;
    }

    //endregion

    //region getters

    get orderEntryBatchId(): string {
        return this._orderEntryBatchId;
    }

    get id(): string {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get body(): string {
        return this._body;
    }

    get attachments(): string[] {
        return this._attachments;
    }

    get isConfirmationModalOpen(): boolean {
        return this._isConfirmationModalOpen;
    }

    get orderEntryAssociations(): OrderEntryEmailAssociation[] {
        return this._orderEntryAssociations;
    }

    get areAllOrdersSelected(): boolean {
        return this._areAllOrdersSelected;
    }

    get isActionsMenuOpen(): boolean {
        return this._isActionsMenuOpen;
    }

    public setActionsMenu(value: boolean) {
        this._isActionsMenuOpen = value;
    }

    get associationTagsMode(): OrderEntryEmailAssociationsMode {
        return this._associationTagsMode;
    }

//endregion
}