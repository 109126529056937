export class OptionTypeViewModel {
    public readonly id: number;
    public readonly name: string;
    public readonly customerId?: number;
    public readonly subdivisionId?: number;
    
    constructor(id: number, name: string, customerId?: number, subdivisionId?: number) {
        this.id = id;
        this.name = name;
        this.customerId = customerId;
        this.subdivisionId = subdivisionId;
    }
}