import {ICustomerService} from "./ICustomerService";
import {ICustomerGateway} from "../gateways/ICustomerGateway";
import {CustomerSubdivisionTable} from "../domain/CustomerSubdivisionTable";
import {
    CustomerSubdivisionTableData,
    CustomerSubdivisionTableDataPropertyValues
} from "../domain/CustomerSubdivisionTableData";
import {TableColumnArranger} from "../../globalComponents/Table/columnRearrange/TableColumnArranger";
import {TableTitle} from "../../globalComponents/Table/TableTitle";
import {Lookup} from "../../globalComponents/lookup/Lookup";
import {IUserGateway} from "../../userAggregate/gateways/IUserGateway";
import {TableType} from "../../globalComponents/Table/TableType";

export class CustomerService implements ICustomerService {
    private readonly _customerGateway: ICustomerGateway;
    private readonly _userGateway: IUserGateway;
    
    constructor(customerGateway: ICustomerGateway, userGateway: IUserGateway) {
        this._customerGateway = customerGateway;
        this._userGateway = userGateway;
    }
    
    public async listCustomerSubdivisions(customerId: number, pageNumber: number, userId: number): Promise<CustomerSubdivisionTable> {
        const viewModel = await this._customerGateway.ListCustomerSubdivisions(customerId, pageNumber);
        const tableSettings = await this._userGateway.getUserTableSettings(userId, TableType.Subdivisions);
        const tableTitleIds = {
            subdivisionName: 1,
            county: 2,
            buildingDepartment: 3,
            activePlans: 4,
            numberOfLots: 5,
            lotCompletionPercentage: 6
        }
        const customerSubdivisionTableData = viewModel.subdivisionsList.map(x => new CustomerSubdivisionTableData(x.id, x.subdivisionName, x.county, tableTitleIds.county, x.buildingDepartmentName, tableTitleIds.buildingDepartment, x.activePlans, tableTitleIds.activePlans, x.numberOfLots, tableTitleIds.numberOfLots,  x.lotCompletionPercentage, tableTitleIds.lotCompletionPercentage));
        const tableTitles = [
            new TableTitle(tableTitleIds.subdivisionName, "Subdivision Name"),
            new TableTitle(tableTitleIds.county, CustomerSubdivisionTableDataPropertyValues.County),
            new TableTitle(tableTitleIds.buildingDepartment, "Building Department Name"),
            new TableTitle(tableTitleIds.activePlans, CustomerSubdivisionTableDataPropertyValues.ActivePlans),
            new TableTitle(tableTitleIds.numberOfLots, CustomerSubdivisionTableDataPropertyValues.NumberOfLots),
            new TableTitle(tableTitleIds.lotCompletionPercentage, CustomerSubdivisionTableDataPropertyValues.LotCompletionPercentage),
        ];

        const arrangeableColumns = tableTitles.slice(1)?.map(x => new Lookup(x.id, x.displayValue));
        
        
        const result = new CustomerSubdivisionTable(tableTitles, viewModel.customerId, customerSubdivisionTableData, false, new TableColumnArranger(arrangeableColumns));
        
        if(result.hasUserSettings(result.tableTitles, tableSettings.tableHeaderIds)) {
            return result.reorderColumnsByIds(tableSettings.tableHeaderIds);
        } else {
            return result;
        }
    }
}