import {IOmniSearchGateway} from "./IOmniSearchGateway";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {OmniSearchLookupViewModel} from "../viewModels/OmniSearchLookupViewModel";

export default class OmniSearchGateway implements IOmniSearchGateway {
    private readonly _apiGateway: IAPIGateway;
    private readonly _baseUrl: string = '/api/omnisearch';

    constructor(apiGateway: IAPIGateway) {
        this._apiGateway = apiGateway;
    }
    
    Search = async (search: string): Promise<OmniSearchLookupViewModel> => {
        return await this._apiGateway.get(`${this._baseUrl}/search?search=${search}`);
    }
}