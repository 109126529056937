import {ProjectTeam} from "../domain/ProjectTeam";
import {ProjectTeamViewModel} from "../viewModels/ProjectTeamViewModel";


export class ProjectTeamMapper {
    public static MapProjectTeamToProjectTeamViewModel(projectTeam: ProjectTeam): ProjectTeamViewModel{
        return {
            id: projectTeam.id,
            userSkillSetProjectTeamLinks: projectTeam.userSkillSetProjectTeamLinks
        } as ProjectTeamViewModel
    }
    
    public static MapProjectTeamViewModelToProjectTeam(projectTeamViewModel: ProjectTeamViewModel, disciplinePhaseId: number): ProjectTeam {
        return new ProjectTeam(projectTeamViewModel.id, disciplinePhaseId, projectTeamViewModel.userSkillSetProjectTeamLinks);
    }
}