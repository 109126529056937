import {OmniSearchValueObject} from "../valueObjects/OmniSearchValueObject";
import {MutableRefObject} from "react";

export class OmniSearchDomainObject {
    listInnerRef: MutableRefObject<undefined> = { current: undefined };
    isLoaded: boolean = false;
    initialState: OmniSearchValueObject[] = [{ category: "", options: [] }];
    options: OmniSearchValueObject[] = this.initialState;
    selectedCategory: string = "";
    query: string = "";
    //TODO: add pagination


    constructor(listInnerRef: MutableRefObject<undefined>, isLoaded: boolean, initialState: OmniSearchValueObject[], options: OmniSearchValueObject[], selectedCategory: string, query: string) {
        this.listInnerRef = listInnerRef;
        this.isLoaded = isLoaded;
        this.options = options;
        this.selectedCategory = selectedCategory;
        this.query = query;
    }

    handleClearState() {
        this.selectedCategory = "";
        this.options = this.initialState;
        this.query = "";
        return this.initialState;
    }
}