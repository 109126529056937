import {OptionTypeViewModel} from "../../valueObjects/OptionTypeViewModel";
import {ILookupGateway} from "../../infrastructure/lookup/ILookupGateway";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";

export class PricingLevelGateway implements ILookupGateway {
    baseUrl: string = '/api/pricingLevels/'
    private readonly _apiService: IAPIGateway;

    constructor(apiService: IAPIGateway) {
        this._apiService = apiService;
    }

    listOptions(): Promise<OptionTypeViewModel[]> {
        return this._apiService.get(this.baseUrl + "list");
    }
}