import {Combobox} from "@headlessui/react";
import {Dispatch, ReactNode, SetStateAction} from "react";

interface IOmniSearchInputComponentProps {
    icon?: ReactNode,
    setQuery: Dispatch<SetStateAction<string>>,
    placeholderText: string
}   

export const OmniSearchInputComponent = ({...props}: IOmniSearchInputComponentProps) => {
    const {setQuery, icon, placeholderText} = props;
    return (
        <>
            <Combobox.Input
                autoComplete="off"
                aria-label={"combobox input"}
                className={`w-full rounded-md border border-gray-300 bg-white py-2 pr-10 ${icon ? "pl-9" : ""} shadow-sm focus:ring-0 focus:border-primary sm:text-sm`}
                onChange={(event) => {
                    setQuery(event.target.value)
                }}
                placeholder={placeholderText}
            />
            {icon ?
                <div className={"absolute inset-y-0 left-0 flex items-center px-2 focus:outline-none"}>
                    {icon}
                </div> : <></>
            }
        </>

    )
}