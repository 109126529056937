import {Lookup} from "../../globalComponents/lookup/Lookup";

export enum Priority {
    Low,
    Medium,
    High
}

export const PriorityOptions = [
    new Lookup(Priority.Low, "Low"),
    new Lookup(Priority.Medium, "Medium"),
    new Lookup(Priority.High, "High"),
]