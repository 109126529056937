import {IUserService} from "./IUserService";
import {IUserGateway} from "../gateways/IUserGateway";
import {User} from "../domain/User";
import {TableType} from "../../globalComponents/Table/TableType";
import {UserTableSettingsViewModel} from "../viewModels/UserTableSettingsViewModel";

export class UserService implements IUserService {
    private readonly _userGateway: IUserGateway;
    
    constructor(userGateway: IUserGateway) {
        this._userGateway = userGateway;
    }
    
    public async getUserById(userId: number) {
        const result = await this._userGateway.getUser(userId);
        return new User(result.id, result.firstName, result.lastName, result.userType, result.primaryEmail);
    }
    
    public async updateUserTableSettings(userId: number, tableHeaderIds: number[], tableType: TableType): Promise<void> {
        const userSettingsViewModel = new UserTableSettingsViewModel(tableType, tableHeaderIds)
        return await this._userGateway.updateUserTableSettings(userId, userSettingsViewModel); 
    }
}