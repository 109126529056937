export class SubdivisionAdditionalInformationViewModel {
    public readonly buildingDepartmentIds?: number[];
    public readonly dateAdded?: Date;
    public readonly subdivisionStatus?: string;
    public readonly subdivisionPhase?: string;
    
    constructor(buildingDepartmentIds?: number[], dateAdded?: Date, subdivisionStatus?: string, subdivisionPhases?: string) {
        this.buildingDepartmentIds = buildingDepartmentIds;
        this.dateAdded = dateAdded;
        this.subdivisionStatus = subdivisionStatus;
        this.subdivisionPhase = subdivisionPhases;
    }
}