//This is meant to be used in conjunction with the backend for sorting. Since our table lists our paginated, the sorting has to come from the backend.
//If we ever just want front end sorting, we need to make a new class and implement the ISortable interface for that instance. This is the implementation for backend sorting.
import {TableTitle} from "./TableTitle";
import {ISortable} from "../../generics/Sortable/ISortable";
import {SortableOrder} from "../../generics/Sortable/SortableOrder";

export abstract class TableSort implements ISortable {
    protected _tableTitles: TableTitle[];

    protected constructor(tableTitles: TableTitle[]) {
        this._tableTitles = tableTitles;
    }
    
    private replaceTableTitles(tableTitles: TableTitle[]): this {
        const result = this.clone();
        result._tableTitles = tableTitles;
        return result;
    }
    
    private resetAllOtherColumnsToUnsorted(otherColumns: TableTitle[]) {
        return otherColumns.map(x => {
            return new TableTitle(x.id, x.displayValue, x.value, x.sortOrder && SortableOrder.Unsorted);
        });
    }
    
    public specifyTableColumnToBeSorted(sortProperty: string, sortOrder: SortableOrder): this {
        const result =  this.clone();
        
        const resetSortStateOfUI = this.resetAllOtherColumnsToUnsorted(result._tableTitles);
        const tableWithUpdatedTitles = this.replaceTableTitles(resetSortStateOfUI);
        
        const index = tableWithUpdatedTitles._tableTitles.findIndex(x => x.value === sortProperty);

        if(index === -1 || !sortProperty) {
            throw new Error(`The column header ${sortProperty} is not a title that supports sorting.`);
        }

        //region assignSortOrder
        //Applies the correct sort order based on how many times the user has clicked. 
        //User clicks once => the sort order is ascending
        //User clicks twice => the sort order is descending
        //User clicks a third time => the sort order is back in unsorted state
        //User clicks again, rinse and repeat
        let updatedTableTitle: TableTitle;
        switch(sortOrder) {
            case SortableOrder.Descending:
                updatedTableTitle = tableWithUpdatedTitles._tableTitles[index].updateSortOrder(SortableOrder.Unsorted);
                break;
            case SortableOrder.Unsorted:
                updatedTableTitle = tableWithUpdatedTitles._tableTitles[index].updateSortOrder(SortableOrder.Ascending);
                break;
            case SortableOrder.Ascending:
                updatedTableTitle = tableWithUpdatedTitles._tableTitles[index].updateSortOrder(SortableOrder.Descending);
                break;
            default:
                updatedTableTitle = tableWithUpdatedTitles._tableTitles[index].updateSortOrder(SortableOrder.Unsorted);
        }
        //endregion

        tableWithUpdatedTitles._tableTitles.splice(index, 1, updatedTableTitle);

        return tableWithUpdatedTitles;
    }

    protected abstract clone(): this;

    get tableTitles(): TableTitle[] {
        return this._tableTitles;
    }
}