export class LotBulkCreateViewModel {
    public readonly prefix: string;
    public readonly lotNumberRange: string;
    public readonly suffix?: string;
    public readonly customerId: number;
    public readonly trussManufacturerId: number;
    public readonly street?: string;
    public readonly city?: string;
    public readonly zip?: string;
    public readonly county?: string;
    public readonly windExposure?: string;
    public readonly windSpeed?: string;
    public readonly state?: string;


    constructor(prefix: string, lotNumberRange: string, customerId: number, trussManufacturerId: number, suffix?: string, street?: string, city?: string, zip?: string, county?: string, windExposure?: string, windSpeed?: string, state?: string) {
        this.prefix = prefix;
        this.lotNumberRange = lotNumberRange;
        this.suffix = suffix;
        this.customerId = customerId;
        this.trussManufacturerId = trussManufacturerId;
        this.street = street;
        this.city = city;
        this.zip = zip;
        this.county = county;
        this.windExposure = windExposure;
        this.windSpeed = windSpeed;
        this.state = state;
    }
}