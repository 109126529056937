import {IDisciplineGateway} from "./IDisciplineGateway";
import {DisciplineLookupViewModel} from "../viewModels/DisciplineLookupViewModel";
import {DisciplineViewModel} from "../viewModels/DisciplineViewModel";
import {DisciplinePhaseListViewModel} from "../../disciplinePhaseAggregate/viewModels/DisciplinePhaseListViewModel";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";

export class DisciplineGateway implements IDisciplineGateway {
    private readonly _apiService: IAPIGateway;
    private baseUrl: string = "/api/disciplines";
    constructor(apiService: IAPIGateway) {
        this._apiService = apiService;
    }
    public async getDisciplinesList(): Promise<DisciplineViewModel[]> {
        return await this._apiService.get(`${this.baseUrl}/list`)
    }
    
    public async getDisciplineById(id: number): Promise<DisciplineLookupViewModel> {
        return await this._apiService.get(`${this.baseUrl}/${id}`)
    }
    
    public async getDisciplinePhasesByDisciplineId(disciplineId: number): Promise<DisciplinePhaseListViewModel[]> {
        return await this._apiService.get(`${this.baseUrl}/${disciplineId}/disciplinePhase/list`)
    }
}