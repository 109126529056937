export async function handleJsonResponse(response: Response) {
    if (!response.ok) {
        return await response.json().then((x: JSON) => {
            if(response.status >= 500) {
                throw new Error("Uh oh! That wasn't supposed to happen. We are currently experiencing server issues. Please try again later.");
            }
            return Promise.reject(x);
        });
    }
    if(response.status === 202){
        return Promise.resolve();
    }
    if(response.status === 204){
        return Promise.resolve();
    }
    
    return await response.json();
}