import {IDisciplineGateway} from "../gateways/IDisciplineGateway";
import {IRequirementSetGateway} from "../../requirementSetAggregate/gateways/IRequirementSetGateway";
import {DisciplinePhaseMapper} from "../../disciplinePhaseAggregate/utils/DisciplinePhaseMapper";
import {Discipline} from "../domain/Discipline";
import {DisciplinePhase} from "../../disciplinePhaseAggregate/domain/DisciplinePhase";
import {
    DisciplinePhaseSetEditViewModel
} from "../../disciplinePhaseAggregate/viewModels/DisciplinePhaseSetEditViewModel";


export class DisciplineMapper {
    private readonly _disciplineGateway: IDisciplineGateway;
    private readonly _requirementSetGateway: IRequirementSetGateway;

    constructor(disciplineGateway: IDisciplineGateway, requirementSetGateway: IRequirementSetGateway) {
        this._disciplineGateway = disciplineGateway;
        this._requirementSetGateway = requirementSetGateway;
    }

    public async mapDisciplinePhaseSetEditVMToDiscipline(disciplinePhaseSetEditViewModels: DisciplinePhaseSetEditViewModel[]) {
        const disciplinePhaseMapper = new DisciplinePhaseMapper(this._requirementSetGateway);
        const disciplinePhases = await disciplinePhaseMapper.mapDisciplinePhaseSetEditViewModelToRequirementSetDisciplinePhase(disciplinePhaseSetEditViewModels);

        const disciplineMapPromises = disciplinePhases.reduce(async (disciplineMapPromise: Promise<Map<number, Discipline>>, disciplinePhase: DisciplinePhase) => {
            const disciplineMap = await disciplineMapPromise;
            const discipline = disciplineMap.get(disciplinePhase.disciplineId);

            if (discipline) {
                discipline.addDisciplinePhase(disciplinePhase);
            } else {
                const disciplineNameVM = await this._disciplineGateway.getDisciplineById(disciplinePhase.disciplineId);
                disciplineMap.set(disciplinePhase.disciplineId, new Discipline(disciplinePhase.disciplineId, disciplineNameVM.name, [disciplinePhase], false));
            }

            return disciplineMap;
        }, Promise.resolve(new Map<number, Discipline>()));

        const disciplineMap = await disciplineMapPromises;

        return Array.from(disciplineMap.values());
    }
}