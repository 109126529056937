import {Percentage} from "../../valueObjects/percentage/Percentage";

export class FileUpload {
    private _files: File[];
    private _resultMessage: string;
    private _isLoading: boolean;
    private _uploadStatus: Percentage;
    private _isSuccessful?: boolean;


    constructor(files: File[] = [], uploadStatus: Percentage = new Percentage(0), resultMessage: string = "", isSuccessful?: boolean, isLoading: boolean = false) {
        this._files = files
        this._uploadStatus = uploadStatus;
        this._resultMessage = resultMessage;
        this._isSuccessful = isSuccessful;
        this._isLoading = isLoading;
    }

    private clone() {
        return new FileUpload(this._files, this._uploadStatus, this._resultMessage, this._isSuccessful, this._isLoading);
    }


    /**
     * The file limit argument should be specified in bytes. The TypeScript file object's size property is expressed in bytes, so we want to maintain that consistency here.
     * @param fileLimit
     * @param files
     */
    public static isOverLimit(fileLimit: number, files: File[]): boolean {
        const hashMap = new Set<string>();
        files?.map(x => {
            if(x.size > fileLimit) {
                hashMap.add(x.name);
            }
        });
        
        return hashMap.size > 0;
    }
    
    public getFileNames() {
        return this.files?.map((value) => value.name);
    }
    
    //state methods
    public updateFileRange(file: File[]): FileUpload {
        const result = this.clone();
        result._files = file;
        return result;
    }

    public updateUploadStatus(percentage: Percentage): FileUpload {
        const result = this.clone();
        result._uploadStatus = percentage;
        return result;
    }

    public setResultMessage(resultMessage: string) {
        const result = this.clone();
        result._resultMessage = resultMessage;
        return result;
    }
    
    public setIsLoading(isLoading: boolean) {
        const result = this.clone();
        result._isLoading = isLoading;
        return result;
    }

    public setIsSuccessful(isSuccessful: boolean): FileUpload {
        const result = this.clone();
        result._isSuccessful = isSuccessful;
        return result;
    }

    //endregion

    //region getters
    get files(): File[] | undefined {
        return this._files;
    }

    get uploadStatus(): Percentage {
        return this._uploadStatus;
    }

    get resultMessage(): string {
        return this._resultMessage;
    }

    get isSuccessful(): boolean | undefined {
        return this._isSuccessful;
    }
    
    get isLoading(): boolean {
        return this._isLoading;
    }

//endregion
}