import {BuildingDepartmentDeliveryRequirementsEditViewModel} from "./BuildingDepartmentDeliveryRequirementsEditViewModel";
import {BuildingDepartmentLocationEditViewModel} from "./BuildingDepartmentLocationEditViewModel";

export class BuildingDepartmentEditViewModel {
    public readonly id: number;
    public readonly name: string;
    public readonly buildingDepartmentLocationEditViewModel: BuildingDepartmentLocationEditViewModel;
    public readonly buildingDepartmentDeliveryRequirementsEditViewModel: BuildingDepartmentDeliveryRequirementsEditViewModel;


    constructor(buildingDepartmentId: number, name: string, buildingDepartmentLocationEditViewModel: BuildingDepartmentLocationEditViewModel, buildingDepartmentDeliveryRequirementsEditViewModel: BuildingDepartmentDeliveryRequirementsEditViewModel) {
        this.id = buildingDepartmentId;
        this.name = name;
        this.buildingDepartmentLocationEditViewModel = buildingDepartmentLocationEditViewModel;
        this.buildingDepartmentDeliveryRequirementsEditViewModel = buildingDepartmentDeliveryRequirementsEditViewModel;
    }
}