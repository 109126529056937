import {GarageOrientationEnum} from "../../planAggregate/GarageOrientation";
import {JobStatus} from "../domain/JobStatus";
import {Priority} from "../domain/Priority";

export class OrderEntryEditViewModel {
    public readonly customerId: number;
    public readonly subdivisionId: number;
    public readonly lotId: number;
    public readonly planId: number;
    public readonly garageOrientation?: GarageOrientationEnum;
    public readonly componentManufacturerId?: number;
    public readonly dueDate?: Date;
    public readonly jobStatus?: JobStatus;
    public readonly priority?: Priority;
    public readonly trussesIn: Date;


    constructor(customerId: number, subdivisionId: number, planId: number, lotId: number, trussesIn: Date, garageOrientation: GarageOrientationEnum = GarageOrientationEnum.Left, componentManufacturerId?: number, dueDate?: Date, jobStatus?: JobStatus, priority: Priority = Priority.Medium) {
        this.customerId = customerId;
        this.subdivisionId = subdivisionId;
        this.lotId = lotId;
        this.planId = planId;
        this.garageOrientation = garageOrientation;
        this.componentManufacturerId = componentManufacturerId;
        this.dueDate = dueDate;
        this.jobStatus = jobStatus;
        this.priority = priority;
        this.trussesIn = trussesIn;
    }
}