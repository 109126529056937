import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {ITrussManufacturerGateway} from "./ITrussManufacturerGateway";
import {TrussManufacturerListViewModel} from "../viewModels/TrussManufacturerListViewModel";
import {TrussManufacturerLookupViewModel} from "../viewModels/TrussManufacturerLookupViewModel";

export class TrussManufacturerGateway implements ITrussManufacturerGateway {
    private readonly _apiGateway: IAPIGateway;
    private readonly _baseUrl: string = "/api/trussManufacturers"
    
    constructor(apiGateway: IAPIGateway) {
        this._apiGateway = apiGateway;
    }
    
    public async GetAllTrussManufacturers(): Promise<TrussManufacturerListViewModel[]> {
        return this._apiGateway.get(`${this._baseUrl}/list`);
    }
    
    public async GetTrussManufacturerByIdLookup(id: number): Promise<TrussManufacturerLookupViewModel> {
        return this._apiGateway.get(`${this._baseUrl}/${id}/lookup`);
    }
}