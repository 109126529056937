import {CustomerLookupViewModel} from "../../customerAggregate/viewModels/CustomerLookupViewModel";
import {
    TrussManufacturerLookupViewModel
} from "../../trussManufacturerAggregate/viewModels/TrussManufacturerLookupViewModel";
import {LotFormMode} from "../ui/lotForm/LotFormMode";
import {ISpecification} from "../../generics/Specification/ISpecification";
import {SpecificationResult} from "../../generics/Specification/SpecificationResult";
import {ZipCodeSpecification} from "../../generics/Specification/ZipCodeSpecification";
import {CitySpecification} from "../../generics/Specification/CitySpecification";
import {CountySpecification} from "../../generics/Specification/CountySpecification";
import {LotName} from "../valueObjects/LotName";

export class LotForm implements ISpecification<LotForm> {
    private readonly _id: number;
    protected _lotName: LotName;
    protected _idsForBulkAction: number[];
    protected _isLoading: boolean;
    private readonly _subdivisionId: number;
    private readonly _mode: LotFormMode;
    private _formTitle: string;
    protected _prefixPlaceholderText: string;
    protected _isPrefixFieldDisabled: boolean;
    private _street?: string;
    private _city?: string;
    private _state?: string;
    private _zip?: string;
    private _country?: string;
    private _customer: CustomerLookupViewModel;
    private _trussManufacturer: TrussManufacturerLookupViewModel;
    private _windSpeed?: string;
    private _windExposure?: string;

    //region stateMethods
    
    public updateLotName(lotName: LotName): LotForm {
        const result = this.clone();
        result._lotName = lotName;
        return result;
    }

    public addLotsToBulkStatus(lotIds: number[]): LotForm {
        const result = this.clone();
        if (result._mode === LotFormMode.BulkUpdate) {
            lotIds.map(x => {
                return result._idsForBulkAction?.push(x);
            });
        }
        return result;
    }

    public toggleIsLoading(): LotForm {
        const result = this.clone();
        result._isLoading = !result._isLoading;
        return result;
    }

    public finishLoading(): LotForm {
        const result = this.clone();
        result._isLoading = false;
        return result;
    }

    public updateStreet(street: string): LotForm {
        this._street = street;
        return this.clone();
    }

    public updateCity(city: string): LotForm {
        this._city = city;
        return this.clone();
    }

    public updateState(state: string): LotForm {
        this._state = state;
        return this.clone();
    }

    public updateZip(zip: string): LotForm {
        this._zip = zip;
        return this.clone();
    }

    public updateCounty(county: string): LotForm {
        this._country = county;
        return this.clone();
    }

    public updateWindSpeed(windSpeed: string): LotForm {
        this._windSpeed = windSpeed;
        return this.clone();
    }

    public updateWindExposure(windExposure: string): LotForm {
        this._windExposure = windExposure;
        return this.clone();
    }

    public updateCustomer(customer: CustomerLookupViewModel): LotForm {
        this._customer = customer;
        return this.clone();
    }

    public updateTrussManufacturer(trussManufacturer: TrussManufacturerLookupViewModel): LotForm {
        this._trussManufacturer = trussManufacturer;
        return this.clone();
    }

    //endregion

    //region constructor
    constructor(mode: LotFormMode, subdivisionId: number, customer: CustomerLookupViewModel, trussManufacturer: TrussManufacturerLookupViewModel, lotName: LotName, id: number = 0, street?: string, city?: string, state?: string, zip?: string, country?: string, windSpeed?: string, windExposure?: string, lotIdsForBulkAction: number[] = [], isLoading: boolean = false) {
        this._id = id;
        this._mode = mode;
        this._subdivisionId = subdivisionId;
        this._isLoading = isLoading;
        this._lotName = lotName;
        this._prefixPlaceholderText = this.initializePrefixPlaceholderText();
        this._isPrefixFieldDisabled = this._mode === LotFormMode.Update;
        this._street = street;
        this._city = city;
        this._state = state;
        this._zip = zip;
        this._country = country;
        this._windSpeed = windSpeed;
        this._windExposure = windExposure;
        this._customer = customer;
        this._trussManufacturer = trussManufacturer;
        this._idsForBulkAction = lotIdsForBulkAction;
        this._formTitle = this.initializeFormTitle();
    }

    private initializePrefixPlaceholderText(): string {
        if (this._mode === LotFormMode.BulkUpdate || this._mode === LotFormMode.BulkAdd) {
            return this._prefixPlaceholderText = "Ex) [1-5], 8, 10, 14"
        } else if (this._mode === LotFormMode.Update) {
            return this._prefixPlaceholderText = "This number cannot be changed."
        } else if (this._mode === LotFormMode.Add) {
            return this._prefixPlaceholderText = "Lot Number"
        } else {
            return this._prefixPlaceholderText = "";
        }
    }

    private initializeFormTitle(): string {
        if (this._mode === LotFormMode.BulkUpdate) {
            return this._formTitle = `Edit ${this._idsForBulkAction?.length} Lots`;
        } else if (this._mode === LotFormMode.Add) {
            return this._formTitle = `Add Lot`;
        } else if (this._mode === LotFormMode.Update) {
            return this._formTitle = `Edit ${this._lotName?.getFormattedName()}`;
        } else if (this._mode === LotFormMode.BulkAdd) {
            return this._formTitle = `Add Lots`;
        } else {
            return this._formTitle = "";
        }
    }

    //endregion

    //region factory methods
    private clone() {
        return new LotForm(this._mode, this._subdivisionId, this._customer, this._trussManufacturer, this._lotName, this._id, this._street, this._city, this._state, this._zip, this._country, this._windSpeed, this._windExposure, this._idsForBulkAction, this._isLoading);
    }

    public static initializeBulkAddForm(subdivisionId: number) {
        return new LotForm(LotFormMode.BulkAdd, subdivisionId, new CustomerLookupViewModel(0, ""), new TrussManufacturerLookupViewModel(0, ""), new LotName("","",""), 0, "", "", "", "", "", "", "", [], false);
    }
    
    public static initializeAddForm(subdivisionId: number) {
        return new LotForm(LotFormMode.Add, subdivisionId, new CustomerLookupViewModel(0, ""), new TrussManufacturerLookupViewModel(0, ""), new LotName("","",""), 0, "", "", "", "", "", "", "", [], false);
    }

    //endregion

    //region getters
    get isLoading(): boolean {
        return this._isLoading;
    }

    get isPrefixFieldDisabled(): boolean {
        return this._isPrefixFieldDisabled;
    }

    get formTitle(): string {
        return this._formTitle;
    }

    get id(): number {
        return this._id;
    }

    get subdivisionId(): number {
        return this._subdivisionId;
    }

    get mode(): LotFormMode {
        return this._mode;
    }

    get prefixPlaceholderText(): string {
        return this._prefixPlaceholderText;
    }
    
    public get street() {
        return this._street;
    }

    public get city() {
        return this._city;
    }

    public get state() {
        return this._state;
    }

    public get zip() {
        return this._zip;
    }

    public get country() {
        return this._country;
    }

    public get windSpeed() {
        return this._windSpeed;
    }

    public get windExposure() {
        return this._windExposure;
    }

    public get trussManufacturer() {
        return this._trussManufacturer;
    }

    get customer(): CustomerLookupViewModel {
        return this._customer;
    }

    get lotName(): LotName {
        return this._lotName;
    }

    get idsForBulkAction(): number[] {
        return this._idsForBulkAction;
    }

//endregion

    //region specification
    public isSatisfiedBy(value: LotForm): SpecificationResult {
        const zipCodeSpecification = new ZipCodeSpecification();
        const citySpecification = new CitySpecification();
        const countySpecification = new CountySpecification();
        
        if (value.zip && !zipCodeSpecification.isSatisfiedBy(value.zip).isSatisfied) {
            return new SpecificationResult(false, zipCodeSpecification.isSatisfiedBy(value.zip!).errorReason);
        } else if (value.city && !citySpecification.isSatisfiedBy(value.city).isSatisfied) {
            return new SpecificationResult(false, citySpecification.isSatisfiedBy(value.city!).errorReason)
        } else if (value.country && !countySpecification.isSatisfiedBy(value.country).isSatisfied) {
            return new SpecificationResult(false, countySpecification.isSatisfiedBy(value.country!).errorReason);
        } else {
            return new SpecificationResult(true);
        }
    }

    //endregion
}