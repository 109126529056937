import {ICloneable} from "../../../generics/Cloneable/ICloneable";

export class AddressViewModel implements ICloneable<AddressViewModel> {
    public readonly id: number;
    public displayName?: string;
    public address1: string;
    public city: string;
    public state: string;
    public address2?: string;
    public zipCode: string;
    public country: string;
    public phone?: string;


    constructor(id: number, address1: string, city: string, state: string, zipCode: string, country: string, phone: string = "", address2: string = "", displayName: string = "") {
        this.id = id;
        this.displayName = displayName;
        this.address1 = address1;
        this.city = city;
        this.state = state;
        this.address2 = address2;
        this.zipCode = zipCode;
        this.country = country;
        this.phone = phone;
    }
    
    public clone() {
        return new AddressViewModel(this.id, this.address1, this.city, this.state, this.zipCode, this.country, this.phone, this.address2, this.displayName);
    }

    public static CreateSingleEmptyDisplayAddress(): AddressViewModel[] {
        return [
            new AddressViewModel(0, "", "", "", "", "", "", "", "")
        ]
    }
    
    //region state methods
    
    public updateDisplayName(displayName: string) {
        const result = this.clone();
        result.displayName = displayName;
        return result;
    }

    public updateAddress1(address1: string) {
        const result = this.clone();
        result.address1 = address1;
        return result;
    }

    public updateAddress2(address2: string) {
        const result = this.clone();
        result.address2 = address2;
        return result;    }

    public updateCity(city: string) {
        const result = this.clone();
        result.city = city;
        return result;
    }

    public updateState(state: string) {
        const result = this.clone();
        result.state = state;
        return result;
    }

    public updateZip(zip: string) {
        const result = this.clone();
        result.zipCode = zip;
        return result;
    }

    public updateCountry(country: string) {
        const result = this.clone();
        result.country = country;
        return result;
    }
    
    public updatePhone(phone: string) {
        const result = this.clone();
        result.phone = phone;
        return result;
    }
    
    //endregion
}