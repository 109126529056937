export class BuildingDepartmentLocationEditViewModel {
    public readonly city: string;
    public readonly state: string;
    public readonly county: string;

    constructor(city: string, state: string, county: string) {
        this.city = city;
        this.state = state;
        this.county = county;
    }
}