export const SUBDIVISION_TOOLTIP_DISABLED =`The "Customer" field must be chosen before you can choose a "Subdivision".`

export const PLAN_TOOLTIP_DISABLED = `The "Subdivision" field must be chosen before you can choose a "Plan".`

export const LOT_TOOLTIP_DISABLED = `The "Subdivision" field must be chosen before you can choose a "Lot".`

export const GARAGE_ORIENTATION_TOOLTIP_DISABLED = `The "Plan" field must be chosen before you can choose a "Garage Orientation".`

export function generateGarageOrientationEnabledText(defaultValue: string) {
    return `The default value of ${defaultValue} is inherited from the chosen Plan.`;
}