import {PricingMatrixUnitType} from "./PricingMatrixUnitType";
import {IPricingMatrixField} from "./IPricingMatrixField";
import {InputGroupTypes} from "../../globalComponents/InputGroupTypes";

export class PricingMatrix {
    private readonly _id: number;
    private readonly _disciplinePhaseId: number | undefined;
    private readonly _disciplineSetId: number;
    private _baseElevationSfur: IPricingMatrixField;
    private _baseElevationSfurUnitType: PricingMatrixUnitType;
    private _additionalElevationSfur: IPricingMatrixField;
    private _additionalElevationSfurUnitType: PricingMatrixUnitType;
    private _additionalFoundations: IPricingMatrixField;
    private _majorOptions: IPricingMatrixField;
    private _minorOptions: IPricingMatrixField;
    private _bonusDiscount: IPricingMatrixField;
    private _bonusDiscountType: PricingMatrixUnitType;
    private _additionalSolarOrientations: IPricingMatrixField;


    constructor(id: number, disciplinePhaseId: number | undefined, disciplineSetId: number, baseElevationSfur: IPricingMatrixField, baseElevationSfurUnitType: PricingMatrixUnitType, additionalElevationSfur: IPricingMatrixField, additionalElevationSfurUnitType: PricingMatrixUnitType, additionalFoundations: IPricingMatrixField, majorOptions: IPricingMatrixField, minorOptions: IPricingMatrixField, bonusDiscount: IPricingMatrixField, bonusDiscountType: PricingMatrixUnitType, additionalSolarOrientations: IPricingMatrixField) {
        this._id = id;
        this._disciplinePhaseId = disciplinePhaseId;
        this._disciplineSetId = disciplineSetId;
        this._baseElevationSfur = baseElevationSfur;
        this._baseElevationSfurUnitType = baseElevationSfurUnitType;
        this._additionalElevationSfur = additionalElevationSfur;
        this._additionalElevationSfurUnitType = additionalElevationSfurUnitType;
        this._additionalFoundations = additionalFoundations;
        this._majorOptions = majorOptions;
        this._minorOptions = minorOptions;
        this._bonusDiscount = bonusDiscount;
        this._bonusDiscountType = bonusDiscountType;
        this._additionalSolarOrientations = additionalSolarOrientations;
    }

    get id(): number {
        return this._id;
    }

    get disciplinePhaseId(): number | undefined {
        return this._disciplinePhaseId;
    }

    get disciplineSetId(): number {
        return this._disciplineSetId;
    }


    get baseElevationSfur(): IPricingMatrixField {
        return this._baseElevationSfur;
    }

    get baseElevationSfurUnitType(): PricingMatrixUnitType {
        return this._baseElevationSfurUnitType;
    }

    get additionalElevationSfur(): IPricingMatrixField {
        return this._additionalElevationSfur;
    }

    get additionalElevationSfurUnitType(): PricingMatrixUnitType {
        return this._additionalElevationSfurUnitType;
    }

    get additionalFoundations(): IPricingMatrixField {
        return this._additionalFoundations;
    }

    get majorOptions(): IPricingMatrixField {
        return this._majorOptions;
    }

    get minorOptions(): IPricingMatrixField {
        return this._minorOptions;
    }

    get bonusDiscount(): IPricingMatrixField {
        return this._bonusDiscount;
    }

    get bonusDiscountType(): PricingMatrixUnitType {
        return this._bonusDiscountType;
    }

    get additionalSolarOrientations(): IPricingMatrixField {
        return this._additionalSolarOrientations;
    }

    public SetBaseElevation(baseElevation: string): PricingMatrix {
        const convertedBaseElevation = this.EnsurePropertiesOfCurrencyCanBeConvertedBackToNumber(baseElevation);
        const newPricingMatrix = this.ClonePricingMatrix(this);
        newPricingMatrix.baseElevationSfur.value = convertedBaseElevation.toString();
        return newPricingMatrix;
    }
    public SetBaseElevationUnitType(baseElevationUnitType: PricingMatrixUnitType): PricingMatrix {
        const newPricingMatrix = this.ClonePricingMatrix(this);
        newPricingMatrix._baseElevationSfurUnitType = baseElevationUnitType;
        return newPricingMatrix;
    }

    public SetAdditionalElevationSfur(additionalElevationSfur: string) {
        const convertedAdditionalElevation = this.EnsurePropertiesOfCurrencyCanBeConvertedBackToNumber(additionalElevationSfur);
        const newPricingMatrix = this.ClonePricingMatrix(this);
        newPricingMatrix.additionalElevationSfur.value = convertedAdditionalElevation.toString();
        return newPricingMatrix;
    }
    
    public SetAdditionalElevationsUnitType(additionalElevationsUnitType: PricingMatrixUnitType): PricingMatrix {
        const newPricingMatrix = this.ClonePricingMatrix(this);
        newPricingMatrix._additionalElevationSfurUnitType = additionalElevationsUnitType;
        return newPricingMatrix;
    }
    
    public SetAdditionalFoundations(additionalFoundations: string): PricingMatrix {
        const convertedAdditionalFoundations = this.EnsurePropertiesOfCurrencyCanBeConvertedBackToNumber(additionalFoundations);
        const newPricingMatrix = this.ClonePricingMatrix(this);
        newPricingMatrix.additionalFoundations.value = convertedAdditionalFoundations.toString();
        return newPricingMatrix;
    }
    
    public SetMajorOptions(majorOptions: string): PricingMatrix {
        const convertedMajorOptions = this.EnsurePropertiesOfCurrencyCanBeConvertedBackToNumber(majorOptions);
        const newPricingMatrix = this.ClonePricingMatrix(this);
        newPricingMatrix.majorOptions.value = convertedMajorOptions.toString();
        return newPricingMatrix;
    }
    
    public SetMinorOptions(minorOptions: string): PricingMatrix {
        const convertedMinorOptions = this.EnsurePropertiesOfCurrencyCanBeConvertedBackToNumber(minorOptions);
        const newPricingMatrix = this.ClonePricingMatrix(this);
        newPricingMatrix.minorOptions.value = convertedMinorOptions.toString();
        return newPricingMatrix;
    }

    public SetBonusDiscount(bonusDiscount: string) {
        const convertedBonusDiscount = this.EnsurePropertiesOfCurrencyCanBeConvertedBackToNumber(bonusDiscount);
        const newPricingMatrix = this.ClonePricingMatrix(this);
        newPricingMatrix.bonusDiscount.value = convertedBonusDiscount.toString();
        return newPricingMatrix;
    }
    public SetBonusDiscountType(bonusDiscountType: PricingMatrixUnitType): PricingMatrix {
        const newPricingMatrix = this.ClonePricingMatrix(this);
        newPricingMatrix._bonusDiscountType = bonusDiscountType;
        return newPricingMatrix;
    }

    public SetAdditionalSolarOrientation(additionalSolarOrientation: string): PricingMatrix {
        const convertedAdditionalSolarOrientation = this.EnsurePropertiesOfCurrencyCanBeConvertedBackToNumber(additionalSolarOrientation);
        const newPricingMatrix = this.ClonePricingMatrix(this);
        newPricingMatrix.additionalSolarOrientations.value = convertedAdditionalSolarOrientation.toString();
        return newPricingMatrix;
    }
    
    public SetBonusDiscountInputGroupType(pricingMatrixUnitType: PricingMatrixUnitType): PricingMatrix {
        const newPricingMatrix = this.ClonePricingMatrix(this);
        if(pricingMatrixUnitType === PricingMatrixUnitType.Percentage){
            newPricingMatrix.bonusDiscount.inputGroupType = InputGroupTypes.Percentage;
        }
        else {
            newPricingMatrix.bonusDiscount.inputGroupType = InputGroupTypes.Currency;
        }
        return newPricingMatrix;
    }

    public ClonePricingMatrix(pricingMatrix: PricingMatrix) {
        return new PricingMatrix(
            pricingMatrix._id,
            pricingMatrix.disciplinePhaseId,
            pricingMatrix.disciplineSetId,
            pricingMatrix.baseElevationSfur,
            pricingMatrix.baseElevationSfurUnitType,
            pricingMatrix.additionalElevationSfur,
            pricingMatrix.additionalElevationSfurUnitType,
            pricingMatrix.additionalFoundations,
            pricingMatrix.majorOptions,
            pricingMatrix.minorOptions,
            pricingMatrix.bonusDiscount,
            pricingMatrix.bonusDiscountType,
            pricingMatrix.additionalSolarOrientations,
        )
    }

    private EnsurePropertiesOfCurrencyCanBeConvertedBackToNumber<T>(value: T): number {
        if (typeof value === 'string') {
            return Number(parseFloat(value.replace(/,/g, '')).toFixed(2));
        } else {
            return Number(value);
        }
    }
}