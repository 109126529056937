import {IDisciplineGateway} from "../../disciplineAggregate/gateways/IDisciplineGateway";
import {IRequirementSetGateway} from "../../requirementSetAggregate/gateways/IRequirementSetGateway";
import {PricingMatrixFactory} from "../../pricingMatrixAggregate/Factories/PricingMatrixFactory";
import {ProjectTeamMapper} from "../../projectTeamAggregate/utils/ProjectTeamMapper";
import {DisciplinePhase} from "../domain/DisciplinePhase";
import {DisciplinePhaseSetEditViewModel} from "../viewModels/DisciplinePhaseSetEditViewModel";


export class DisciplinePhaseMapper {
    private readonly _disciplineGateway?: IDisciplineGateway;
    private readonly _requirementSetGateway?: IRequirementSetGateway;

    constructor(requirementSetGateway?: IRequirementSetGateway, disciplineGateway?: IDisciplineGateway) {
        if (disciplineGateway) {
            this._disciplineGateway = disciplineGateway;
        }
        if (requirementSetGateway)
            this._requirementSetGateway = requirementSetGateway;
    }

    //On Calling this method, you must ensure to pass in the requirementSetGateway in the constructor
    public async mapDisciplinePhaseSetEditViewModelToRequirementSetDisciplinePhase(disciplinePhaseSetEditViewModel: DisciplinePhaseSetEditViewModel[]) {
        const pricingMatrixFactory = new PricingMatrixFactory();
        const disciplinePhasesPromises = disciplinePhaseSetEditViewModel.map(async (x) => {
            //Validating that this method has to have the requirementSetGateway set in the ctor, otherwise, projectTeam will be null
            const projectTeamViewModel = await this._requirementSetGateway!.getRequirementSetProjectTeamByProjectTeamId(x.projectTeamId);
            const pricingMatrixViewModel = await this._requirementSetGateway!.getRequirementSetPricingMatrixByPricingMatrixId(x.pricingMatrixEditViewModel?.id);
            const projectTeam = ProjectTeamMapper.MapProjectTeamViewModelToProjectTeam(projectTeamViewModel, x.disciplinePhaseId);
            return new DisciplinePhase(x.disciplinePhaseId, x.disciplinePhaseName, x.disciplineId, false, pricingMatrixFactory.createPricingMatrixFromEditViewModel(pricingMatrixViewModel, x.disciplinePhaseId), projectTeam);
        });

        return await Promise.all(disciplinePhasesPromises);
    }
}