//This view model is used for LotEdit, BulkLotEdit, BulkLotAdd, and LotAdd
export class LotEditViewModel {
    public readonly lotId: number;
    public readonly prefix: string;
    public readonly range: string;
    public readonly suffix?: string;
    public readonly customerId: number;
    public readonly trussManufacturerId: number;
    public readonly street?: string;
    public readonly city?: string;
    public readonly zip?: string;
    public readonly county?: string;
    public readonly state?: string;
    public readonly windExposure?: string;
    public readonly windSpeed?: string;


    constructor(lotId: number, prefix: string, range: string, customerId: number, trussManufacturerId: number, suffix?: string, street?: string, city?: string, zip?: string, county?: string, state?: string, windSpeed?: string, windExposure?: string) {
        this.lotId = lotId;
        this.prefix = prefix;
        this.range = range;
        this.suffix = suffix;
        this.customerId = customerId;
        this.trussManufacturerId = trussManufacturerId;
        this.street = street;
        this.city = city;
        this.zip = zip;
        this.county = county;
        this.state = state;
        this.windSpeed = windSpeed;
        this.windExposure = windExposure;
    }
}
