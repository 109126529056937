import {OmniSearchContextProvider} from "../../omniSearchAggregate/state/OmniSearchContextProvider";
import {HeaderNavbar} from "./HeaderNavbar";

export const HeaderNavbarContainer = () => {

    return (
        <OmniSearchContextProvider>
            <HeaderNavbar/>
        </OmniSearchContextProvider>
    )
}