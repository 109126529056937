export class LotName {
    protected _prefix: string;
    protected _range: string;
    protected _suffix?: string;

    constructor(prefix: string, range: string, suffix?: string) {
        this._prefix = prefix;
        this._range = range;
        this._suffix = suffix;
    }
    
    public clone(): LotName {
        return new LotName(this._prefix, this._range, this._suffix);
    }


    //region getters
    get prefix(): string {
        return this._prefix;
    }

    get range(): string {
        return this._range;
    }

    get suffix(): string | undefined {
        return this._suffix;
    }
    
    //endregion

    public getFormattedName(): string {
        const rangePart = this._range ? `-${this._range}` : "";
        const suffixPart = this._suffix ? `-${this._suffix}` : "";
        return `${this._prefix ?? ""}${rangePart}${suffixPart}`;
    }
 
    //region state methods
    public updatePrefix(prefix: string): LotName {
        const result = this.clone();
        result._prefix = prefix;
        return result;
    }

    public updateRange(range: string): LotName {
        const result = this.clone();
        result._range = range;
        return result;
    }

    public updateSuffix(suffix: string): LotName {
        const result = this.clone();
        result._suffix = suffix;
        return result;
    }
    //endregion
}







