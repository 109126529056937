import {SubdivisionAdditionalInformation} from "../valueObjects/SubdivisionAdditionalInformation";
import {SubdivisionCustomerLink} from "../valueObjects/subdivisionCustomer/SubdivisionCustomerLink";


export class SubdivisionForm {
    private _customerId: number;
    private readonly _id: number;
    private _name: string;
    private readonly _externalSubdivisionId: number;
    private _subdivisionCustomerLink: Array<SubdivisionCustomerLink>;
    private _windSpeed?: string;
    private _windExposure?: string;
    private _seismicCategory?: string;
    private _subdivisionAdditionalInformation?: SubdivisionAdditionalInformation;

    //region state methods
    public updateName(name: string): SubdivisionForm {
        this._name = name;
        return this.clone();
    }

    public updateCustomerId(customerId: number): SubdivisionForm {
        this._customerId = customerId;
        return this.clone();
    }
    
    public updateWindSpeed(windSpeed: string): SubdivisionForm {
        this._windSpeed = windSpeed;
        return this.clone();
    }

    public updateWindExposure(windExposure: string): SubdivisionForm {
        this._windExposure = windExposure;
        return this.clone();
    }

    public updateSeismicCategory(seismicCategory: string): SubdivisionForm {
        this._seismicCategory = seismicCategory;
        return this.clone();
    }

    public updateSubdivisionAdditionalInformation(additionalInfo: SubdivisionAdditionalInformation): SubdivisionForm {
        this._subdivisionAdditionalInformation = additionalInfo;
        return this.clone();
    }

    public replaceSubdivisionCustomerLink(subdivisionCustomerLink: SubdivisionCustomerLink[]): SubdivisionForm {
        this._subdivisionCustomerLink = subdivisionCustomerLink;
        return this.clone();
    }
    
    public addSubdivisionCustomerLink(subdivisionCustomerLink: SubdivisionCustomerLink): SubdivisionForm {
        this._subdivisionCustomerLink.push(subdivisionCustomerLink);
        return this.clone();
    }

    public removeSubdivisionCustomerLink(id: number): SubdivisionForm {
        if(this._subdivisionCustomerLink.length === 1) {
            throw new Error("A subdivision must have at least one customer");
        }
        
        const index = this._subdivisionCustomerLink.findIndex(x => x.id === id);
        if (index === -1) {
            throw new Error(`Subdivision Customer with ID ${id} is not tied to this subdivision`);
        }        
        this._subdivisionCustomerLink.splice(index, 1);
        return this.clone();
    }
    
    public updateSubdivisionCustomerLinkInformation(subdivisionCustomerLinkId: number, subdivisionCustomerLink: SubdivisionCustomerLink): SubdivisionForm {
        const index = this._subdivisionCustomerLink.findIndex(x => x.id === subdivisionCustomerLinkId);
        this._subdivisionCustomerLink.splice(index, 1, subdivisionCustomerLink);
        return this.clone();
    }
    
    private clone() {
        return new SubdivisionForm(this._customerId, this._id, this._name, this._externalSubdivisionId, this._subdivisionCustomerLink, this._windSpeed, this._windExposure, this._seismicCategory, this._subdivisionAdditionalInformation);
    }
    
    //endregion
    
    //region constructor

    constructor(customerId: number, id: number, name: string, externalSubdivisionId: number, subdivisionCustomerLinks: Array<SubdivisionCustomerLink>, windSpeed?: string, windExposure?: string, seismicCategory?: string, subdivisionAdditionalInformation?: SubdivisionAdditionalInformation) {
        this._customerId = customerId;
        this._id = id;
        this._name = name;
        this._externalSubdivisionId = externalSubdivisionId;
        this._windSpeed = windSpeed;
        this._windExposure = windExposure;
        this._seismicCategory = seismicCategory;
        this._subdivisionAdditionalInformation = subdivisionAdditionalInformation;
        if (subdivisionCustomerLinks.length < 1) {
            this._subdivisionCustomerLink = [new SubdivisionCustomerLink(1, 0, "", 0, "", [])];
        } else {
            this._subdivisionCustomerLink = subdivisionCustomerLinks;
        }
    }
    
    //endregion

    //region getters
    public get customerId(){
        return this._customerId;
    }

    public get id() {
        return this._id;
    }

    public get name() {
        return this._name;
    }

    public get externalSubdivisionId() {
        return this._externalSubdivisionId;
    }

    public get windSpeed() {
        return this._windSpeed;
    }

    public get windExposure() {
        return this._windExposure;
    }

    public get seismicCategory() {
        return this._seismicCategory;
    }
    
    public get subdivisionAdditionalInformation() {
        return this._subdivisionAdditionalInformation;
    }

    public get subdivisionCustomerLink() {
        return this._subdivisionCustomerLink;
    }
    //endregion
}