import {ISubdivisionService} from "./ISubdivisionService";
import {ICustomerGateway} from "../../customerAggregate/gateways/ICustomerGateway";
import {SubdivisionDetailsViewModel} from "../viewModels/SubdivisionDetailsViewModel";
import {
    SubdivisionCustomerInformationDetailsViewModel
} from "../viewModels/SubdivisionCustomerInformationDetailsViewModel";
import {LookupViewModel} from "../../infrastructure/lookup/LookupViewModel";
import {SubdivisionLotsTable} from "../domain/SubdivisionLotsTable";
import {ISubdivisionGateway} from "../gateway/ISubdivisionGateway";
import {SortViewModel} from "../../generics/viewModels/SortViewModel";
import {SortableOrder} from "../../generics/Sortable/SortableOrder";
import {LotForm} from "../../lotAggregate/domain/LotForm";
import {ITrussManufacturerGateway} from "../../trussManufacturerAggregate/gateway/ITrussManufacturerGateway";
import {LotEditViewModel} from "../../lotAggregate/viewModels/LotEditViewModel";
import {LotFormMode} from "../../lotAggregate/ui/lotForm/LotFormMode";
import {SubdivisionLotTableData} from "../domain/SubdivisionLotTableData";
import {LotCollectionViewModel} from "../../lotAggregate/viewModels/LotCollectionViewModel";
import {CustomerLookupViewModel} from "../../customerAggregate/viewModels/CustomerLookupViewModel";
import {
    TrussManufacturerLookupViewModel
} from "../../trussManufacturerAggregate/viewModels/TrussManufacturerLookupViewModel";
import {LotBulkEditViewModel} from "../../lotAggregate/viewModels/LotBulkEditViewModel";
import {LotName} from "../../lotAggregate/valueObjects/LotName";
import {LotBulkDeleteViewModel} from "../../lotAggregate/viewModels/LotBulkDeleteViewModel";
import {LotBulkCreateViewModel} from "../../lotAggregate/viewModels/LotBulkCreateViewModel";
import {IUserGateway} from "../../userAggregate/gateways/IUserGateway";
import {TableColumnArranger} from "../../globalComponents/Table/columnRearrange/TableColumnArranger";
import {Lookup} from "../../globalComponents/lookup/Lookup";
import {TableTitle} from "../../globalComponents/Table/TableTitle";
import {TableType} from "../../globalComponents/Table/TableType";
import {LotStatus} from "../../lotAggregate/LotStatus";

export class SubdivisionService implements ISubdivisionService {
    private readonly _customerGateway: ICustomerGateway;
    private readonly _subdivisionGateway: ISubdivisionGateway;
    private readonly _trussManufacturerGateway: ITrussManufacturerGateway;
    private readonly _userGateway: IUserGateway;

    constructor(customerGateway: ICustomerGateway, subdivisionGateway: ISubdivisionGateway, trussManufacturerGateway: ITrussManufacturerGateway, userGateway: IUserGateway) {
        this._customerGateway = customerGateway;
        this._subdivisionGateway = subdivisionGateway;
        this._trussManufacturerGateway = trussManufacturerGateway;
        this._userGateway = userGateway;
    }

    public async getSubdivisionDetails(subdivisionId: number): Promise<SubdivisionDetailsViewModel> {
        const customerDetails = await this._subdivisionGateway.getSubdivisionCustomerDetails(subdivisionId);
        const subdivisionDetails = await this._subdivisionGateway.getSubdivisionDetails(subdivisionId);
        const customerPlans = await Promise.all(customerDetails.map(async x => {
            return await this._customerGateway.ListCustomerPlansLookup(x.customerId);
        }));

        const customerDetailsViewModels = await Promise.all(customerDetails?.map(async (x, index) => {
            return new SubdivisionCustomerInformationDetailsViewModel(
                x.customerId,
                x.customerName,
                x.trussManufacturerId,
                x.trussManufacturerName,
                x.communitySpecificInfo,
                customerPlans[index].map(y => new LookupViewModel(y.id, y.name))
            );
        }));

        return new SubdivisionDetailsViewModel(
            subdivisionDetails.subdivisionKeyInformation,
            customerDetailsViewModels,
            subdivisionDetails.location,
            subdivisionDetails.deliveryRequirement,
            subdivisionDetails.additionalInformation,
            subdivisionDetails.subdivisionProgress,
            subdivisionDetails.subdivisionProjectTeam
        );

    }

    public async getSubdivisionLotsTable(subdivisionId: number, pageNumber: number, userId?: number, sortOrder?: SortableOrder, sortProperty?: string): Promise<SubdivisionLotsTable> {
        const sortViewModel = new SortViewModel(sortProperty ?? "", sortOrder ?? SortableOrder.Unsorted);
        const result = await this._subdivisionGateway.listLotTableDetailsById(subdivisionId, pageNumber, sortViewModel);
        const subdivisionLookup = await this._subdivisionGateway.getSubdivisionLookupById(subdivisionId);
        const tableSettings = userId ? await this._userGateway.getUserTableSettings(userId, TableType.Lots) : undefined;
        const tableTitles = [
            new TableTitle(0, "Lot Name", "name", SortableOrder.Unsorted),
            new TableTitle(1, "Lot Status", "lotStatus", SortableOrder.Unsorted),
            new TableTitle(2, "Customer Name", "customerName", SortableOrder.Unsorted),
            new TableTitle(3, "Truss Manufacturer Name", "trussManufacturerName", SortableOrder.Unsorted),
            new TableTitle(4, "Wind Exposure", "windExposure", SortableOrder.Unsorted),
            new TableTitle(5, "Wind Speed", "windSpeed", SortableOrder.Unsorted),
        ];
        
        const arrangeableColumns = tableTitles.slice(1).map(x => new Lookup(x.id, x.displayValue));        

        let subdivisionTableData: SubdivisionLotTableData[] = [];
        if(Array.isArray(result)) {
            subdivisionTableData = result?.map(x => {
                return new SubdivisionLotTableData(x.id, x.name, new Map<number, LotStatus>().set(1, x.lotStatus), new Map<number, string>().set(2, x.customerName), new Map<number, string>().set(3, x.trussManufacturerName), new Map<number, string>().set(4, x.windExposure), new Map<number, string>().set(5, x.windSpeed), false, false, false);
            })
        }
        
        const subdivisionLotsTable = new SubdivisionLotsTable(subdivisionId, subdivisionLookup.name, subdivisionTableData, tableTitles, [], false, false, new TableColumnArranger(arrangeableColumns));
        
        if(tableSettings && subdivisionLotsTable.hasUserSettings(subdivisionLotsTable.tableTitles, tableSettings?.tableHeaderIds)) {
            return subdivisionLotsTable.applyUserSettings(tableSettings.tableHeaderIds);
        } else {
            return subdivisionLotsTable;
        }
    }

    public async getSubdivisionLotFormDetails(subdivisionId: number, lotId: number): Promise<LotForm> {
        const result = await this._subdivisionGateway.getSubdivisionLotFormDetails(subdivisionId, lotId);
        const customer = await this._customerGateway.GetCustomerLookup(result.customerId);
        const trussManufacturer = await this._trussManufacturerGateway.GetTrussManufacturerByIdLookup(result.trussManufacturerId);

        return new LotForm(LotFormMode.Update, subdivisionId, customer, trussManufacturer, new LotName(result.prefix, result.range, result.suffix), lotId, result.street, result.city, result.state, result.zip, result.county, result.windSpeed, result.windExposure);
    }
    
    public async updateSubdivisionLot(subdivisionId:number, lotId:number, lotForm: LotForm): Promise<void> {
        const lotEditViewModel = new LotEditViewModel(lotId, lotForm.lotName.prefix, lotForm.lotName.range, lotForm.customer?.id, lotForm.trussManufacturer?.id, lotForm.lotName.suffix, lotForm.street, lotForm.city, lotForm.zip, lotForm.country, lotForm.state, lotForm.windSpeed, lotForm.windExposure);
        
        if(!lotForm?.isSatisfiedBy(lotForm).isSatisfied) {
            throw new Error(lotForm.isSatisfiedBy(lotForm).errorReason)
        }
        
        return await this._subdivisionGateway.updateSubdivisionLot(subdivisionId, lotId, lotEditViewModel);
    }
    
    public async getLotBulkFormDetails(subdivisionId: number, lotIds: number[]): Promise<LotForm> {
        const lotEditViewModel = await this._subdivisionGateway.getLotRangeUpdateFormDetails(subdivisionId, new LotCollectionViewModel(lotIds));
        let customer: CustomerLookupViewModel;
        let trussManufacturer: TrussManufacturerLookupViewModel;
        const MIXED_VALUES = "Mixed Values";
        
        //If the ID passed in is 0, it is considered an invalid ID, the backend is returning IDs with 0 if multiple lots have conflicting fields. This function ensures that the name of conflicting fields is marked as Mixed when it comes to LookupViewModels that return an ID of 0.
        if(lotEditViewModel.trussManufacturerId === 0 || lotEditViewModel.customerId === 0 || lotEditViewModel.lotId === 0) {
            return new LotForm(LotFormMode.Update, subdivisionId, new CustomerLookupViewModel(0, MIXED_VALUES), new TrussManufacturerLookupViewModel(0, MIXED_VALUES), new LotName(lotEditViewModel.prefix, lotEditViewModel.range, lotEditViewModel.suffix), lotEditViewModel.lotId ?? 0, lotEditViewModel.street, lotEditViewModel.city, lotEditViewModel.state, lotEditViewModel.zip, lotEditViewModel.county, lotEditViewModel.windSpeed, lotEditViewModel.windExposure);
        } else {
            customer = await this._customerGateway.GetCustomerLookup(lotEditViewModel.customerId);
            trussManufacturer = await this._trussManufacturerGateway.GetTrussManufacturerByIdLookup(lotEditViewModel.trussManufacturerId);
            return new LotForm(LotFormMode.Update, subdivisionId, new CustomerLookupViewModel(customer.id, customer.name), new TrussManufacturerLookupViewModel(trussManufacturer.id, trussManufacturer.name), new LotName(lotEditViewModel.prefix, lotEditViewModel.range, lotEditViewModel.suffix), lotEditViewModel.lotId, lotEditViewModel.street, lotEditViewModel.city, lotEditViewModel.state, lotEditViewModel.zip, lotEditViewModel.county, lotEditViewModel.windSpeed, lotEditViewModel.windExposure);
        }
    }
    
    public async deleteSubdivisionLots(subdivisionId: number, lotIds: number[]): Promise<SubdivisionLotsTable> {
        const lotBulkDeleteViewModel = new LotBulkDeleteViewModel(lotIds);
        await  this._subdivisionGateway.deleteLotsBySubdivision(subdivisionId, lotBulkDeleteViewModel);
        return await this.getSubdivisionLotsTable(subdivisionId, 0);
    }
    
    public async updateSubdivisionLots(subdivisionId:number, lotIds: number[], lotForm: LotForm): Promise<void> {
        const lotBulkEditViewModel = new LotBulkEditViewModel(lotIds, new LotEditViewModel(subdivisionId, lotForm.lotName.prefix, lotForm.lotName.range, lotForm.customer?.id, lotForm.trussManufacturer?.id, lotForm.lotName.suffix, lotForm.street, lotForm.city, lotForm.zip, lotForm.country, lotForm.state, lotForm.windSpeed, lotForm.windExposure));

        if(!lotForm.isSatisfiedBy(lotForm).isSatisfied) {
            throw new Error(lotForm.isSatisfiedBy(lotForm).errorReason)
        }
        
        return await this._subdivisionGateway.updateLotsBySubdivision(subdivisionId, lotBulkEditViewModel);
    }
    
    public async addMultipleLotsToSubdivision(subdivisionId:number, lotForm:LotForm) {
        const lotBulkEditViewModel = new LotBulkCreateViewModel(lotForm.lotName.prefix, lotForm.lotName.range, lotForm.customer.id, lotForm.trussManufacturer.id, lotForm.lotName.suffix, lotForm.street, lotForm.city, lotForm.zip, lotForm.country, lotForm.windExposure, lotForm.windSpeed, lotForm.state);
        
        return await this._subdivisionGateway.addMultipleLotsToSubdivision(subdivisionId, lotBulkEditViewModel);
    }
}