import {IPlanGateway} from "./IPlanGateway";
import {PlanEditViewModel} from "../viewModels/PlanEditViewModel";
import {PlanOptionEditViewModel} from "../viewModels/PlanOptionEditViewModel";
import {ElevationOptionEditViewModel} from "../viewModels/ElevationOptionEditViewModel";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {PlanLookupViewModel} from "../viewModels/PlanLookupViewModel";
import {PlanViewModel} from "../viewModels/PlanViewModel";
import {OptionTypeViewModel} from "../../valueObjects/OptionTypeViewModel";
import {PlanOptionListViewModel} from "../viewModels/PlanOptionListViewModel";
import {GarageOrientationEnum} from "../GarageOrientation";

export class PlanGateway implements IPlanGateway {
    private readonly _baseUrl: string = '/api/plans/';
    private readonly _apiService: IAPIGateway;

    constructor(apiService: IAPIGateway) {
        this._apiService = apiService;
    }

    getPlanLookupDetails = async (planId: number, signal?: AbortSignal): Promise<PlanLookupViewModel>  => {
        return await this._apiService.get(this._baseUrl + `${planId}`, signal)
    }

    addPlan = async (planCreateVm: PlanEditViewModel, signal: AbortSignal): Promise<void> => {
        return await this._apiService.post(this._baseUrl, planCreateVm, signal);
    }

    getPlanDetails = async (planId: number): Promise<PlanViewModel> => {
        return await this._apiService.get(this._baseUrl + `${planId}/view`);
    }

    getPlanOptionDetails = async (planId: number, signal: AbortSignal): Promise<OptionTypeViewModel> => {
        return await this._apiService.get(this._baseUrl + `${planId}/planOptions`, signal);
    }

    getPlanFormDetails = async (planId: number): Promise<PlanEditViewModel> => {
        return await this._apiService.get(this._baseUrl + `${planId}/details`);
    }

    addPlanOption = async (planId: number, planOptionEditVM: PlanOptionEditViewModel, signal: AbortSignal): Promise<void> => {
        return await this._apiService.post(this._baseUrl + `${planId}/planOptions/add`, planOptionEditVM, signal);
    }

    addPlanElevation = async (planId: number, elevationCreateVm: ElevationOptionEditViewModel, signal: AbortSignal): Promise<void> => {
        return await this._apiService.post(this._baseUrl + `${planId}/elevations/add`, elevationCreateVm, signal);
    }

    updatePlanElevation = async (planId: number, planElevationEditVM: ElevationOptionEditViewModel, signal: AbortSignal) => {
        return await this._apiService.put(`${this._baseUrl}${planId}/elevations/update`, planElevationEditVM, signal)
    }

    updatePlan = async (planId: number, planEditVM: PlanEditViewModel, signal: AbortSignal) => {
        return await this._apiService.put(`${this._baseUrl}${planId}`, planEditVM, signal)
    }

    updatePlanOption = async (planId: number, planOptionEditVm: PlanOptionEditViewModel, signal: AbortSignal) => {
        return await this._apiService.put(`${this._baseUrl}${planId}/planOptions/update`, planOptionEditVm, signal)
    }
    getPlanOptionsByPlan = async (planId: number): Promise<PlanOptionListViewModel[]> => {
        return await this._apiService.get(`${this._baseUrl}${planId}/planOptions`)
    }

    public async listPlans(): Promise<PlanLookupViewModel[]> {
        return await this._apiService.get(`${this._baseUrl}list`);
    }

    public async getPlanDefaultGarageOrientation(planId: number): Promise<GarageOrientationEnum> {
        return await this._apiService.get(`${this._baseUrl}${planId}/garageOrientation`);
    }
}