import React, {lazy, Suspense, useContext} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {CustomerFormMode} from "./customerAggregate/CustomerFormModeEnum";
import {PlanFormMode} from "./planAggregate/PlanFormMode";
import {UserContext} from "./infrastructure/UserContext";
import {PrivateRoutes} from "./routes/PrivateRoutes";
import {useLoadingIndicator} from "./globalHooks/useLoadingIndicator";
import {Theme} from "./themes/types";
import {SubdivisionMode} from "./subdivisionAggregate/ui/subdivisionForm/SubdivisionMode";
import {BuildingDepartmentMode} from "./buildingDepartmentAggregate/BuildingDepartmentMode";
import {BuildingDepartmentURLs} from "./routes/BuildingDepartmentURLs";
import {SubdivisionURLs} from "./routes/SubdivisionURLs";
import {SettingsURLs} from "./routes/SettingsURLs";
import {UserURLs} from "./routes/UserURLs";
import {VariancePurchaseOrderURLs} from "./routes/VariancePurchaseOrderURLs";
import {LoginURLs} from "./routes/LoginURLs";
import {CustomerURLs} from "./routes/CustomerURLs";
import {LotURLs} from "./routes/LotURLs";
import {LotFormMode} from "./lotAggregate/ui/lotForm/LotFormMode";
import {OrderEntryURLS} from "./routes/OrderEntryURLS";
import {Loading} from "./globalComponents/loading/Loading";

//Lazy Load PagesT
const VerifyEmail = lazy(() => import('./pages/VerifyEmail/VerifyEmail').then(module => ({default: module.VerifyEmail})));
const LoginMagicLinkView = lazy(() => import('./pages/MagicLink/LoginMagicLink/LoginMagicLinkView').then(module => ({default: module.LoginMagicLinkView})));
const ConfirmationPurchaseOrderView = lazy(() => import('./pages/VPO/ConfirmationPurchaseOrder/ConfirmationPurchaseOrderView').then(module => ({default: module.ConfirmationPurchaseOrderView})));
const VPOFormView = lazy(() => import('./pages/VPO/VPOFormView').then(module => ({default: module.VPOFormView})))
const Login = lazy(() => import('./pages/Login/Login').then(module => ({default: module.Login})));
const FinalizePurchaseOrderView = lazy(() => import("./pages/VPO/FinalizePurchaseOrder/FinalizePurchaseOrderView").then(module => ({default: module.FinalizePurchaseOrderView})));
const InternalVPOFormView = lazy(() => import('./pages/VPO/Internal/InternalVPOFormView').then(module => ({default: module.InternalVPOFormView})));
const CustomerView = lazy(() => import("./pages/Customers/CustomerView").then(module => ({default: module.CustomerView})));
const CustomerFormView = lazy(() => import('./pages/Customers/CustomerFormView').then(module => ({default: module.CustomerFormView})));
const PlanView = lazy(() => import('./pages/Plans/PlanView').then(module => ({default: module.PlanView})));
const PlanFormView = lazy(() => import('./pages/Plans/PlanFormView').then(module => ({default: module.PlanFormView})));
const UserAdd = lazy(() => import('./pages/Users/UserAdd/UserAddView').then(module => ({default: module.UserAdd})));
const UserListView = lazy(() => import("./pages/Users/UserList/UserListView").then(module => ({default: module.UserListView})));
const UserEditView = lazy(() => import("./pages/Users/UserEdit/UserEditView").then(module => ({default: module.UserEditView})));
const Settings = lazy(() => import("./pages/Settings/Settings").then(module => ({default: module.Settings})));
const BuildingDepartmentFormView = lazy(() => import("./pages/buildingDepartments/BuildingDepartmentFormView").then(module => ({default: module.BuildingDepartmentFormView})));
const SubdivisionsView = lazy(() => import("./pages/Subdivisions/SubdivisionsView").then(module => ({default: module.SubdivisionsView})));
const SubdivisionFormView = lazy(() => import("./pages/Subdivisions/SubdivisionFormView").then(module => ({default: module.SubdivisionFormView})));
const BuildingDepartmentView = lazy(() => import("./pages/buildingDepartments/BuildingDepartmentView").then(module => ({default: module.BuildingDepartmentView})));
const BuildingDepartmentsDetailsView = lazy(() => import("./pages/buildingDepartments/BuildingDepartmentsDetailsView").then(module => ({default: module.BuildingDepartmentsDetailsView})));
const LotFormView = lazy(() => import("./pages/Lots/LotFormView").then(module => ({default: module.LotFormView})));
const LotDetailView = lazy(() => import("./pages/Lots/LotDetailView").then(module => ({default: module.LotDetailView})));
const NewSsReissueView = lazy(() => import("./pages/OrderEntry/NewSsReissueView").then(module => ({default: module.NewSsReissueView})));
const OrderEntryFormView = lazy(() => import("./pages/OrderEntry/OrderEntryFormView").then(module => ({default: module.OrderEntryFormView})));


function App() {

    const {isLoading, loadingStyleForWholePage} = useLoadingIndicator();
    const {user} = useContext(UserContext);
    const {userType} = user;
    const Internal = userType === 0;
    const External = userType === 1;
    const Admin = userType === 2;

    return (
        <>
            <Suspense fallback={
                <div className={loadingStyleForWholePage}>
                    <Loading isLoading={isLoading}/>
                </div>}
            >
                <Routes>
                    {/*region login*/}
                    <Route index path={LoginURLs.loginView} element={<Login theme={Theme.BeLit}/>}/>
                    <Route index path={VariancePurchaseOrderURLs.vpoLogin} element={<Login theme={Theme.TrueHouse}/>}/>
                    <Route index path={LoginURLs.verifyEmailView} element={<VerifyEmail theme={Theme.BeLit}/>}/>
                    <Route index path={VariancePurchaseOrderURLs.verifyEmailView}
                           element={<VerifyEmail theme={Theme.TrueHouse}/>}/>
                    <Route index path={VariancePurchaseOrderURLs.magicLinksDecodeView}
                           element={<LoginMagicLinkView theme={Theme.TrueHouse}/>}/>
                    <Route index path={LoginURLs.magicLinksDecodeView}
                           element={<LoginMagicLinkView theme={Theme.BeLit}/>}/>
                    <Route path="/" element={<Navigate to={LoginURLs.loginView}/>}/>
                    {/*endregion*/}
                    <Route element={<PrivateRoutes/>}>
                        {/*region customers*/}
                        <Route path={CustomerURLs.customerView} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Admin || Internal ? <CustomerView/> : <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={CustomerURLs.planView} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Admin || Internal ? <PlanView/> : <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={CustomerURLs.planOptions} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Admin || Internal ? <PlanView/> : <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={CustomerURLs.planVersions} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Admin || Internal ? <PlanView/> : <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={CustomerURLs.planRevisions} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Admin || Internal ? <PlanView/> : <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={CustomerURLs.customerFormAddView} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Admin || Internal ? <CustomerFormView mode={CustomerFormMode.Add}/> :
                                    <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={CustomerURLs.customerFormEditView} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Admin || Internal ? <CustomerFormView mode={CustomerFormMode.Update}/> :
                                    <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={CustomerURLs.planFormAddView} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Admin || Internal ? <PlanFormView mode={PlanFormMode.Add}/> :
                                    <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={CustomerURLs.planFormEditView} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Admin || Internal ? <PlanFormView mode={PlanFormMode.Update}/> :
                                    <Navigate to={LoginURLs.loginView}/>)}/>
                        {/*endregion*/}
                        {/*region vpo*/}
                        <Route path={VariancePurchaseOrderURLs.vpoView} element={
                            isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (External || Internal ? <VPOFormView/> : <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={VariancePurchaseOrderURLs.internalEditView} element={
                            isLoading ?
                                <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Internal ?
                                    <InternalVPOFormView/> : <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={VariancePurchaseOrderURLs.confirmationView} element={
                            isLoading ?
                                <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (External ?
                                    <ConfirmationPurchaseOrderView/> : <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={VariancePurchaseOrderURLs.internalConfirmationView} element={
                            isLoading ?
                                <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (Internal ?
                                    <ConfirmationPurchaseOrderView/> : <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={VariancePurchaseOrderURLs.finalizeView} element={
                            isLoading ?
                                <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                : (External ?
                                    <FinalizePurchaseOrderView/> : <Navigate to={LoginURLs.loginView}/>)}/>
                        <Route path={VariancePurchaseOrderURLs.verifyView}
                               element={
                                   isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div>
                                       : (External || Internal ? <ConfirmationPurchaseOrderView/> :
                                           <Navigate to={LoginURLs.loginView}/>)}/>
                        {/*endregion*/}
                        {/*region users*/}
                        <Route path={UserURLs.listView}
                               element={Admin ? <UserListView/> : <Navigate to={LoginURLs.loginView}/>}/>
                        <Route path={UserURLs.editView}
                               element={Admin ? <UserEditView/> : <Navigate to={LoginURLs.loginView}/>}/>
                        <Route path={UserURLs.addView}
                               element={Admin ? <UserAdd/> : <Navigate to={LoginURLs.loginView}/>}/>
                        {/*endregion*/}
                        {/*region settings*/}
                        <Route path={SettingsURLs.settingsView}
                               element={Admin ? <Settings/> : <Navigate to={LoginURLs.loginView}/>}/>
                        {/*endregion*/}
                        {/*region subdivisions*/}
                        <Route path={SubdivisionURLs.addView}
                               element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                   Admin ? <SubdivisionFormView mode={SubdivisionMode.Add}/> :
                                       <Navigate to={LoginURLs.loginView}/>}/>
                        <Route path={SubdivisionURLs.editView}
                               element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                   Admin ? <SubdivisionFormView mode={SubdivisionMode.Update}/> :
                                       <Navigate to={LoginURLs.loginView}/>}/>
                        <Route path={SubdivisionURLs.subdivisionView}
                               element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                   Admin ? <SubdivisionsView/> : <Navigate to={LoginURLs.loginView}/>}/>
                        {/*endregion*/}
                        {/*regions lots*/}
                        <Route
                            path={LotURLs.singleLotAddForm}
                            element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                Admin ? <LotFormView mode={LotFormMode.Add}/> : <Navigate to={LoginURLs.loginView}/>}
                        />
                        <Route
                            path={LotURLs.bulkLotAddForm}
                            element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                Admin ? <LotFormView mode={LotFormMode.BulkAdd}/> :
                                    <Navigate to={LoginURLs.loginView}/>}
                        />
                        <Route
                            path={LotURLs.bulkLotEditForm}
                            element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                Admin ? <LotFormView mode={LotFormMode.BulkUpdate}/> :
                                    <Navigate to={LoginURLs.loginView}/>}/>
                        <Route
                            path={LotURLs.singleLotEditForm}
                            element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                Admin ? <LotFormView mode={LotFormMode.Update}/> : <Navigate to={LoginURLs.loginView}/>}
                        />
                        <Route
                            path={LotURLs.lotDetailView}
                            element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                Admin ? <LotDetailView/> : <Navigate to={LoginURLs.loginView}/>}
                        />
                        {/*endregion*/}
                        {/*region buildingDepartment */}
                        <Route path={BuildingDepartmentURLs.addView}
                               element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                   Admin ? <BuildingDepartmentFormView mode={BuildingDepartmentMode.Add}/> :
                                       <Navigate to={LoginURLs.loginView}/>}/>
                        <Route path={BuildingDepartmentURLs.editView}
                               element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                   Admin ? <BuildingDepartmentFormView mode={BuildingDepartmentMode.Update}/> :
                                       <Navigate to={LoginURLs.loginView}/>}/>
                        <Route path={BuildingDepartmentURLs.listView}
                               element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                   Admin ? <BuildingDepartmentView/> : <Navigate to={LoginURLs.loginView}/>}/>
                        <Route path={BuildingDepartmentURLs.detailsView}
                               element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                   Admin ? <BuildingDepartmentsDetailsView mode={BuildingDepartmentMode.ReadOnly}/> :
                                       <Navigate to={LoginURLs.loginView}/>}/>
                        {/*endregion */}
                        {/*region OrderEntry*/}
                        <Route path={OrderEntryURLS.newSsReissueView}
                               element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                   Admin ? <NewSsReissueView/> : <Navigate to={LoginURLs.loginView}/>}/>
                        <Route path={OrderEntryURLS.orderEntryBatchView}
                               element={isLoading ? <div className={loadingStyleForWholePage}><Loading isLoading={isLoading}/></div> :
                                   Admin ? <OrderEntryFormView/> : <Navigate to={LoginURLs.loginView}/>}/>
                        {/*endregion */}
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}

export default App;