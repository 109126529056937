import {IRequirementSetService} from "./IRequirementSetService";
import {IRequirementSetRepository} from "../repos/IRequirementSetRepository";
import { RequirementSet } from "requirementSetAggregate/domain/RequirementSet";

export class RequirementSetService implements IRequirementSetService {
    private readonly _requirementSetRepository: IRequirementSetRepository;

    constructor(requirementSetRepository: IRequirementSetRepository) {
        this._requirementSetRepository = requirementSetRepository;
    }

    public async getRequirementSetByCustomerId(customerId: number): Promise<RequirementSet> {
        return await this._requirementSetRepository.getRequirementSetByCustomerId(customerId);
    }
    public async getRequirementSetByPlanId(planId: number): Promise<RequirementSet> {
        return await this._requirementSetRepository.getRequirementSetByPlanId(planId);
    }
    
    public async getEmptyRequirementSet(): Promise<RequirementSet> {
        return await this._requirementSetRepository.getEmptyRequirementSet();
    }
}