import primaryLogo from "../assets/TrueHousePrimaryLogo.png"
import verticalLogo from "../assets/TrueHouseSecondaryLogo.backup.png"
import secondaryLogo from "../assets/TrueHouseSecondaryLogo350x350.png"
import favicon from "../assets/TruehouseFavicon.png"
import {ITheme} from "./types";
export const TrueHouse: ITheme = {
    name: "TrueHouse",
    colors: {
        offWhite: 'rgba(244, 244, 244, 1)',
        primaryGray: '#58595B',
        secondaryGray: '#9CA3AF',
        disabled: '#E5E7EB',
        darkPrimary: '#32336A',
        primary: '#32336A',
        primaryHover: "#242668",
        primaryHalf: 'rgba(155,21,33,0.5)',
        secondary: '#32336A',
        "default-200": "#C6C6C6",
        "default-500": "#58595B",
        black: '#323232',
        success: '#2EB93A',
    },
    logos: {
        primary: primaryLogo,
        secondary: secondaryLogo,
        verticalNav: verticalLogo,
        favicon: favicon
    }
}
