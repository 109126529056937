import {Combobox} from "@headlessui/react";
import {OptionTypeViewModel} from "../../valueObjects/OptionTypeViewModel";
import {useNavigate} from "react-router-dom";
import {extractErrorMessage} from "../../utilities/extractErrorMessage";
interface IOmniSearchRowProps {
    option: OptionTypeViewModel,
    classNames: (string: string) => string,
    categoryOption: string,
}

export const OmniSearchOptionRowComponent = ({...props}: IOmniSearchRowProps) => {
    const {option, categoryOption, classNames} = props;
    const navigate = useNavigate();

    //Need to keep the handler here. It causes unexpected behavior when moved to class.
    const handleNavigation = (id: number, category: string, customerId?: number, subdivisionId?: number): string => {
        const path = "#";
        try {
            switch(category){
                case "Plans":
                    navigate(`/customers/${customerId}/plans/${id}`);
                    break;
                case "Customers":
                    navigate(`/${category.toLowerCase()}/${id}`);
                    break;
                case "Subdivisions":
                    navigate(`/${category.toLowerCase()}/${id}`);
                    break;
                case "Lots":
                    navigate(`/subdivisions/${subdivisionId}/lots/${id}`);
                    break;
            }
            return path;
        }
        catch(e: unknown){
            const message = extractErrorMessage(e);
            alert(message);
            return "";
        }
    }
    
    return(
        <Combobox.Button
                         className={"w-full hover:bg-gray-100 hover:cursor-pointer"}
                         onClick={() => handleNavigation(option.id, categoryOption, option?.customerId, option?.subdivisionId)}>
            <Combobox.Option
                aria-label={`option ${option.id}`}
                value={option}
                className={'relative select-none py-2 flex justify-start ml-5'}
            >
                <span className={classNames('block truncate text-gray-800 font-normal')}>{option.name}</span>
            </Combobox.Option>
        </Combobox.Button>
    )
}