import {BuildingDepartmentRow} from "./BuildingDepartmentRow";
import {TableTitle} from "../../globalComponents/Table/TableTitle";
import {TableColumnArranger} from "../../globalComponents/Table/columnRearrange/TableColumnArranger";

export class BuildingDepartmentTable {
    private _buildingDepartmentTableTitles: TableTitle[];
    private _isActionPanelOpen: boolean;
    private _buildingDepartmentRows: BuildingDepartmentRow[];
    private _tableColumnArranger: TableColumnArranger;
    private _isLoading: boolean;
    
    constructor(buildingDepartmentTableTitles: TableTitle[], buildingDepartmentRow: BuildingDepartmentRow[], tableColumnArranger: TableColumnArranger, isLoading: boolean = false, isActionPanelOpen: boolean = false) {
        this._buildingDepartmentTableTitles = buildingDepartmentTableTitles;
        this._buildingDepartmentRows = buildingDepartmentRow;
        this._isActionPanelOpen = isActionPanelOpen;
        this._tableColumnArranger = tableColumnArranger;
        this._isLoading = isLoading;
    }
    
    private clone() {
        return new BuildingDepartmentTable(this._buildingDepartmentTableTitles, this._buildingDepartmentRows, this._tableColumnArranger, this._isLoading, this._isActionPanelOpen);
    }

    //region state methods
    public updateTableColumnArranger(tableColumnArranger: TableColumnArranger): BuildingDepartmentTable {
        const result = this.clone();
        result._tableColumnArranger = tableColumnArranger;
        return result;
    }

    public applyUserSettings(columnIds: number[]): BuildingDepartmentTable {
        const result = this.clone();
        //First Item in array (Subdivision Name) can't be rearranged.
        const firstItem = result._buildingDepartmentTableTitles[0];
        let slicedTitles = result._buildingDepartmentTableTitles.slice(1);

        //Sort array, excluding the first item
        slicedTitles = columnIds?.map(id => {
            return slicedTitles.find(x => x.id === id)!;
        });

        //Add the first item back to the array
        //Array check here is for moq.ts. I believe it might struggle to recognize the spread operator.
        if (Array.isArray(slicedTitles)) {
            result._buildingDepartmentTableTitles = [firstItem, ...slicedTitles];
        }
        return result;
    }
    
    public openActionPanel() {
        const result = this.clone();
        result._isActionPanelOpen = true;
        return result;
    }

    public closeActionPanel() {
        const result = this.clone();
        result._isActionPanelOpen = false;
        return result;
    }
    
    public toggleIsLoading(): BuildingDepartmentTable {
        const result = this.clone();
        result._isLoading = !result._isLoading;
        return result;
    }
    
    public replaceItemInBuildingDepartmentRow(buildingDepartmentRow: BuildingDepartmentRow): BuildingDepartmentTable {
        const result = this.clone();
        const index = result._buildingDepartmentRows.findIndex(x => x.id === buildingDepartmentRow.id);
        result._buildingDepartmentRows.splice(index, 1, buildingDepartmentRow);
        return result;
    }
    //endregion
    
    public hasUserSettings(buildingDepartmentTableTitles: TableTitle[], tableSettings: number[]): boolean {
        return buildingDepartmentTableTitles.slice(1).length === tableSettings.length;
    }
    
    //region getters 

    get buildingDepartmentTableTitles(): TableTitle[] {
        return this._buildingDepartmentTableTitles;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get buildingDepartmentRows(): BuildingDepartmentRow[] {
        return this._buildingDepartmentRows;
    }
    
    get isActionPanelOpen(): boolean {
        return this._isActionPanelOpen;
    }

    get tableColumnArranger(): TableColumnArranger {
        return this._tableColumnArranger;
    }

//endregion
}