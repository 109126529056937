import {AddressType} from "../../../customerAggregate/AddressType";
import {AddressSelection} from "../../Address/viewModels/AddressOptions";

export class DeliveryRequirementsViewModel {
    public electronicFileSetup: string;
    public printRequirements: string;
    public packingRequirements: string;
    public printDeliveryMethod: string;
    public customerAddressType: AddressType;
    public eReceipts: string;


    constructor(electronicFileSetup: string, printRequirements: string, packingRequirements: string, printDeliveryMethod: string, selectedAddress: AddressType, eReceipts: string) {
        this.electronicFileSetup = electronicFileSetup;
        this.printRequirements = printRequirements;
        this.packingRequirements = packingRequirements;
        this.printDeliveryMethod = printDeliveryMethod;
        this.customerAddressType = selectedAddress;
        this.eReceipts = eReceipts;
    }

    public clone() {
        return new DeliveryRequirementsViewModel(this.electronicFileSetup, this.printRequirements, this.packingRequirements, this.printDeliveryMethod, this.customerAddressType, this.eReceipts);
    }

    public updateElectronicFileSetup(electronicFileSetup: string) {
        const result = this.clone();
        result.electronicFileSetup = electronicFileSetup;
        return result;
    }

    public updatePrintRequirements(printRequirements: string) {
        const result = this.clone();
        result.printRequirements = printRequirements;
        return result;
    }

    public updatePackingRequirements(packingRequirements: string) {
        const result = this.clone();
        result.packingRequirements = packingRequirements;
        return result;
    }

    public updatePrintDeliveryMethod(printDeliveryMethod: string) {
        const result = this.clone();
        result.printDeliveryMethod = printDeliveryMethod;
        return result;
    }
    
    public updateEReceipts(eReceipts: string){
        const result = this.clone();
        result.eReceipts = eReceipts;
        return result;
    }
    
    public updateSelectedAddress(selectedAddress: AddressType){
        const result = this.clone();
        result.customerAddressType = selectedAddress;
        return result;
    }
    
    public getAddressTypeValue(): AddressSelection {
        if(this.customerAddressType === AddressType.Shipping) {
            return AddressSelection.Shipping;
        } else if(this.customerAddressType === AddressType.Billing) {
            return AddressSelection.Billing;
        } else {
            return AddressSelection.Default
        }
    }
}