import { LotEditViewModel } from "./LotEditViewModel";

export class LotBulkEditViewModel {
  public readonly lotIds: number[];
  public readonly lotEditViewModel: LotEditViewModel;

  constructor(lotIds: number[], lotEditViewModel: LotEditViewModel) {
    this.lotIds = lotIds;
    this.lotEditViewModel = lotEditViewModel;
  }
}
