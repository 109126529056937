import {IOrderEntryBatchFormService} from "./IOrderEntryBatchFormService";
import {IOrderEntryBatchFormRepository} from "../repository/IOrderEntryBatchFormRepository";
import {FileUpload} from "../../globalComponents/fileUpload/FileUpload";
import {OrderEntryBatchForm} from "../domain/OrderEntryBatchForm";
import {OrderEntryForm} from "../domain/OrderEntryForm";

export class OrderEntryBatchFormService implements IOrderEntryBatchFormService {
    private readonly _orderEntryBatchRepository: IOrderEntryBatchFormRepository;

    constructor(orderEntryBatchRepository: IOrderEntryBatchFormRepository) {
        this._orderEntryBatchRepository = orderEntryBatchRepository;
    }

    public async initializeSsReissue(fileUpload: FileUpload): Promise<string> {
        return await this._orderEntryBatchRepository.initializeSsReissue(fileUpload);
    }

    /**
     * Gets orderEntryBatchForm data from backend and populates form components with the necessary options based on the form's current state
     * @param {string} batchId
     * @returns promise resolves with orderEntryBatchForm populated by backened data
     */
    public async getOrderEntryBatchForm(batchId: string): Promise<OrderEntryBatchForm> {
        return await this._orderEntryBatchRepository.getOrderEntryBatchForm(batchId);
    }

    public async addEmailsToOrderEntryBatch(orderEntryBatchId: string, newOrderEntryEmails: File[]): Promise<void> {
        return await this._orderEntryBatchRepository.addEmailsToOrderEntryBatch(orderEntryBatchId, newOrderEntryEmails);
    }

    public async updateOrderEntry(orderEntryBatchId: string, orderEntryForm: OrderEntryForm) {
        return await this._orderEntryBatchRepository.updateOrderEntry(orderEntryBatchId, orderEntryForm);
    }

    public async addNewOrderEntry(orderEntryBatchId: string) {
        return await this._orderEntryBatchRepository.addNewOrderEntry(orderEntryBatchId);
    }

    public async addAndDuplicateOrderEntry(orderEntryBatchId: string) {
        return await this._orderEntryBatchRepository.addAndDuplicateOrderEntry(orderEntryBatchId);
    }

    public async determineAssociationStatusOfOrderEntryEmail(orderEntryBatchId: string, orderEntryEmailId: string, orderEntryId: string, isSelected: boolean): Promise<void> {
        return await this._orderEntryBatchRepository.determineAssociationStatusOfOrderEntryEmail(orderEntryBatchId, orderEntryEmailId, orderEntryId, isSelected);
    }

    public async determineAssociationStatusOfAllOrderEntryEmails(orderEntryBatchId: string, orderEntryEmailId: string, isAssociated: boolean): Promise<void> {
        if(isAssociated) {
            return await this._orderEntryBatchRepository.associateOrderEntryEmailWithAllOrderEntries(orderEntryBatchId, orderEntryEmailId);
        } else {
            return await this._orderEntryBatchRepository.unAssociateOrderEntryEmailWithAllOrderEntries(orderEntryBatchId, orderEntryEmailId);
        }    }

    public async removeOrderEntryEmailFromOrderEntryBatch(orderEntryBatchId: string, orderEntryEmailId: string): Promise<OrderEntryBatchForm> {
        await this._orderEntryBatchRepository.removeOrderEntryEmailFromOrderEntryBatch(orderEntryBatchId, orderEntryEmailId)
        return await this._orderEntryBatchRepository.getOrderEntryBatchForm(orderEntryBatchId);
    }


    public async addAndDuplicateOrderEntryWithCustomer(orderEntryBatchId: string) {
        return await this._orderEntryBatchRepository.addAndDuplicateOrderEntryWithCustomer(orderEntryBatchId);
    }
    
    public async removeOrderEntryFromBatch(orderEntryBatchId: string, orderEntryId: string): Promise<OrderEntryBatchForm> {
        return await this._orderEntryBatchRepository.removeOrderEntryFromBatch(orderEntryBatchId, orderEntryId)
            .then(() => {
                return this._orderEntryBatchRepository.getOrderEntryBatchForm(orderEntryBatchId);
            });
    }
    
    public async submitOrderEntryBatch(orderEntryBatchId: string) {
        return await this._orderEntryBatchRepository.submitOrderEntryBatch(orderEntryBatchId);
    }
}