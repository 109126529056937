import {IPlanVersionGateway} from "./IPlanVersionGateway";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {PlanVersionListViewModel} from "../../planAggregate/viewModels/PlanVersionListViewModel";

export class PlanVersionGateway implements IPlanVersionGateway {
    baseUrl: string = '/api/planVersions/';
    private readonly _apiService: IAPIGateway;

    constructor(apiService: IAPIGateway) {
        this._apiService = apiService;
    }

    GetPlanVersionsByPlanId = async (planId: number): Promise<PlanVersionListViewModel[]> => {
        return await this._apiService.get(`${this.baseUrl}${planId}`)
    }
}