import {BuildingDepartmentLocation} from "../valueObjects/BuildingDepartmentLocation";
import {BuildingDepartmentDeliveryRequirements} from "../valueObjects/BuildingDepartmentDeliveryRequirements";
import {BuildingDepartmentMode} from "../BuildingDepartmentMode";

export class BuildingDepartmentForm {
    private readonly _id: number;
    private _isActionPanelOpen: boolean;
    private _isLoading: boolean;
    private readonly _mode: BuildingDepartmentMode;
    private _name: string;
    private _buildingDepartmentLocation: BuildingDepartmentLocation;
    private _buildingDepartmentDeliveryRequirements: BuildingDepartmentDeliveryRequirements;

    //region state methods
    public updateName(name: string): BuildingDepartmentForm {
        this._name = name;
        return this.clone();
    }
    
    public updateState(state: string): BuildingDepartmentForm {
        this._buildingDepartmentLocation.state = state;
        return this.clone();
    }
    
    public updateCity(city: string): BuildingDepartmentForm {
        this._buildingDepartmentLocation.city = city;
        return this.clone();
    }
    
    public updateCounty(county: string): BuildingDepartmentForm {
        this._buildingDepartmentLocation.county = county;
        return this.clone();
    }
    
    public updateElectronicFileSetup(electronicFileSetup: string): BuildingDepartmentForm {
        this._buildingDepartmentDeliveryRequirements.electronicFileSetup = electronicFileSetup;
        return this.clone();
    }
    
    public updatePrintRequirementSheetSize(printRequirementSheetSize: string): BuildingDepartmentForm {
        this._buildingDepartmentDeliveryRequirements.printRequirementSheetSize = printRequirementSheetSize;
        return this.clone();
    }
    
    public updatePrintRequirementNumberOfCopies(printRequirementNumberOfCopies: number): BuildingDepartmentForm {
        this._buildingDepartmentDeliveryRequirements.printRequirementNumberOfCopies = printRequirementNumberOfCopies;
        return this.clone();
    }

    public updatePackingRequirements(packingRequirements: string): BuildingDepartmentForm {
        this._buildingDepartmentDeliveryRequirements.packingRequirements = packingRequirements;
        return this.clone();
    }
    
    public updatePrintDeliveryMethod(printDeliveryMethod: string): BuildingDepartmentForm {
        this._buildingDepartmentDeliveryRequirements.printDeliveryMethod = printDeliveryMethod;
        return this.clone();
    }
    
    public updateAddress(address: string): BuildingDepartmentForm {
        this._buildingDepartmentDeliveryRequirements.address = address;
        return this.clone();
    }
    
    public updateEReceipts(eReceipts: number): BuildingDepartmentForm {
        this._buildingDepartmentDeliveryRequirements.eReceipts = eReceipts;
        return this.clone();
    }
    
    public toggleActionPanel() {
        const result = this.clone();
        result._isActionPanelOpen = !result._isActionPanelOpen;
        return result;
    }
    
    public closeActionPanel() {
        const result = this.clone();
        result._isActionPanelOpen = false;
        return result;
    }
    
    //endregion
    
    //region factory methods
    public static createEmptyForm(): BuildingDepartmentForm {
        return new BuildingDepartmentForm(
            0,
            false,
            false,
            BuildingDepartmentMode.Add,
            "", 
            new BuildingDepartmentLocation("", "", ""), 
            new BuildingDepartmentDeliveryRequirements("", "", 0, "", "", "", 0));
    }

    //endregion
    
    private clone() {
        return new BuildingDepartmentForm(this._id, this._isActionPanelOpen, this._isLoading, this._mode, this._name, new BuildingDepartmentLocation(this._buildingDepartmentLocation.city, this._buildingDepartmentLocation.state, this._buildingDepartmentLocation.county), new BuildingDepartmentDeliveryRequirements(this._buildingDepartmentDeliveryRequirements.electronicFileSetup, this._buildingDepartmentDeliveryRequirements.printRequirementSheetSize, this._buildingDepartmentDeliveryRequirements.printRequirementNumberOfCopies, this._buildingDepartmentDeliveryRequirements.packingRequirements, this._buildingDepartmentDeliveryRequirements.printDeliveryMethod, this._buildingDepartmentDeliveryRequirements.address, this._buildingDepartmentDeliveryRequirements.eReceipts));
    }


    //region getters
    get id(): number {
        return this._id;
    }

    get isActionPanelOpen(): boolean {
        return this._isActionPanelOpen;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get mode(): BuildingDepartmentMode {
        return this._mode;
    }

    get name(): string {
        return this._name;
    }

    get buildingDepartmentLocation(): BuildingDepartmentLocation {
        return this._buildingDepartmentLocation;
    }

    get buildingDepartmentDeliveryRequirements(): BuildingDepartmentDeliveryRequirements {
        return this._buildingDepartmentDeliveryRequirements;
    }
    //endregion

    constructor(id: number, isOpen: boolean, isLoading: boolean, mode: BuildingDepartmentMode, name: string, buildingDepartmentLocation: BuildingDepartmentLocation, buildingDepartmentDeliveryRequirements: BuildingDepartmentDeliveryRequirements) {
        this._id = id;
        this._isActionPanelOpen = isOpen;
        this._isLoading = isLoading;
        this._mode = mode;
        this._name = name;
        this._buildingDepartmentLocation = buildingDepartmentLocation;
        this._buildingDepartmentDeliveryRequirements = buildingDepartmentDeliveryRequirements;
    }
}