import {IComponentManufacturerGateway} from "./IComponentManufacturerGateway";
import {IAPIGateway} from "../../infrastructure/IAPIGateway";
import {ComponentManufacturerLookupViewModel} from "../viewModels/ComponentManufacturerLookupViewModel";

export class ComponentManufacturerGateway implements IComponentManufacturerGateway {
    private readonly _baseUrl: string;
    private readonly _apiGateway: IAPIGateway;
    constructor(apiGateway: IAPIGateway) {
        this._baseUrl = "/api/componentManufacturer";
        this._apiGateway = apiGateway;
    }

    public async listAllComponentManufacturers(): Promise<ComponentManufacturerLookupViewModel[]> {
        return await this._apiGateway.get(`${this._baseUrl}/list`);
    }
    
    public async listComponentManufacturerLookup(componentManufacturerId: number): Promise<ComponentManufacturerLookupViewModel> {
        return await this._apiGateway.get(`${this._baseUrl}/${componentManufacturerId}/lookup`);
    }
}