import {OptionTypeViewModel} from "../../valueObjects/OptionTypeViewModel";

export class OmniSearchValueObject {
    public category: string;
    public options: OptionTypeViewModel[];
    
    constructor(category: string, options: OptionTypeViewModel[]) {
        this.category = category;
        this.options = options;
    }
}