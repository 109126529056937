import {Combobox} from "@headlessui/react";
import {OmniSearchOptionRowComponent} from "./OmniSearchOptionRowComponent";
import {OptionTypeViewModel} from "../../valueObjects/OptionTypeViewModel";
import {OmniSearchValueObject} from "../valueObjects/OmniSearchValueObject";
import {Ref} from "react";

interface IOmniSearchOptionsComponentProps {
    listInnerRef: Ref<HTMLUListElement> | undefined,
    query: string,
    isLoaded: boolean,
    options: OmniSearchValueObject[],
}

export const OmniSearchOptionsComponent = ({...props}: IOmniSearchOptionsComponentProps) => {
    const {query, isLoaded, options, listInnerRef} = props;

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <>
            {query !== "" && (
                <Combobox.Options ref={listInnerRef} aria-label={"combobox options"}
                                  className="no-scrollbar absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary focus:outline-none sm:text-sm">
                    {query !== "" && options.map((categoryOption, index: number) => {
                        return (
                            <div key={index}>
                                {categoryOption?.options?.length > 0 && (
                                    <>
                                        {(index > 0 && categoryOption?.options) && (<hr className={"border-primary"}/>)}
                                        <span
                                            className={"flex justify-start mx-5 pt-2 font-sans text-base font-medium text-primary py-1"}>{categoryOption.category}</span>
                                        <div key={index}>
                                            {isLoaded && categoryOption.options.map((option: OptionTypeViewModel) => {
                                                return (
                                                    <OmniSearchOptionRowComponent
                                                        key={option.id}
                                                        categoryOption={categoryOption.category}
                                                        option={option}
                                                        classNames={classNames}/>
                                                )
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>

                        )
                    })}
                </Combobox.Options>
            )}
        </>
    )
}