export async function handleBlobResponse(response: Response): Promise<Blob> {
    if (!response.ok) {
        return await response.blob().then((x) => {
            if(response.status >= 500) {
                throw new Error("Uh oh! That wasn't supposed to happen. We are currently experiencing server issues. Please try again later.");
            }
            return Promise.reject(x);
        });
    }
    return await response.blob();
}
