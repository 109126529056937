import {createContext, ReactNode} from "react";
import {IServiceContext} from "./IServiceContext";
import {ServiceProvider} from "./ServiceProvider";

export const ServiceContext = createContext<IServiceContext>(ServiceProvider.getInstance().getServiceContext());

interface ServiceContextProviderProps {
    children: ReactNode;
}
export const ServiceContextProvider = ({ children }: ServiceContextProviderProps) => {
    return (
        <ServiceContext.Provider value={ServiceProvider.getInstance().getServiceContext()}>
            {children}
        </ServiceContext.Provider>
    )
}