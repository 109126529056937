import {SubdivisionLocation} from "../valueObjects/subdivisionLocation/SubdivisionLocation";
import {DeliveryRequirement} from "../../deliveryRequirement/valueObjects/DeliveryRequirement";
import {SubdivisionKeyInformationViewModel} from "./SubdivisionKeyInformationViewModel";
import {SubdivisionAdditionalInformationViewModel} from "./SubdivisionAdditionalInformationViewModel";
import {SubdivisionProgressViewModel} from "./SubdivisionProgressViewModel";
import {SubdivisionProjectTeamViewModel} from "./SubdivisionProjectTeamViewModel";
import {SubdivisionCustomerInformationDetailsViewModel} from "./SubdivisionCustomerInformationDetailsViewModel";

export class SubdivisionDetailsViewModel {
    public readonly subdivisionKeyInformation: SubdivisionKeyInformationViewModel;
    public readonly location?: SubdivisionLocation;
    public readonly deliveryRequirement?: DeliveryRequirement;
    public readonly additionalInformation?: SubdivisionAdditionalInformationViewModel;
    public readonly subdivisionCustomerInformation?: SubdivisionCustomerInformationDetailsViewModel[];
    public readonly subdivisionProgress?: SubdivisionProgressViewModel;
    public readonly subdivisionProjectTeam?: SubdivisionProjectTeamViewModel;


    constructor(subdivisionKeyInformation: SubdivisionKeyInformationViewModel, subdivisionCustomerInformation: SubdivisionCustomerInformationDetailsViewModel[] = [], location?: SubdivisionLocation, deliveryRequirement?: DeliveryRequirement, additionalInformation?: SubdivisionAdditionalInformationViewModel, subdivisionProgress?: SubdivisionProgressViewModel, subdivisionProjectTeam?: SubdivisionProjectTeamViewModel) {
        this.subdivisionKeyInformation = subdivisionKeyInformation
        this.subdivisionCustomerInformation = subdivisionCustomerInformation;
        this.location = location;
        this.deliveryRequirement = deliveryRequirement;
        this.additionalInformation = additionalInformation;
        this.subdivisionProgress = subdivisionProgress;
        this.subdivisionProjectTeam = subdivisionProjectTeam;
    }
}