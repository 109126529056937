import {CustomerInfoViewModel} from "./CustomerInfoViewModel";
import {ContactViewModel} from "../../valueObjects/Contact/viewModels/ContactViewModel";
import {AddressViewModel} from "../../valueObjects/Address/viewModels/AddressViewModel";
import {RequirementSetEditViewModel} from "../../requirementSetAggregate/viewModels/RequirementSetEditViewModel";
import {
    DeliveryRequirementsViewModel
} from "../../valueObjects/DeliveryRequirements/viewModels/DeliveryRequirementsViewModel";

export class CustomerCreateEditViewModel {
    public customerInfo: CustomerInfoViewModel;
    public primaryContact: ContactViewModel;
    public billingContact: ContactViewModel;
    public shippingAddress: AddressViewModel;
    public billingAddress: AddressViewModel;
    public additionalAddresses: AddressViewModel[];
    public requirementSetEditViewModel: RequirementSetEditViewModel;
    public customerDeliveryRequirementsEditViewModel: DeliveryRequirementsViewModel;


    constructor(customerInfo: CustomerInfoViewModel, primaryContact: ContactViewModel, billingContact: ContactViewModel, shippingAddress: AddressViewModel, billingAddress: AddressViewModel, displayAddresses: AddressViewModel[] = [], requirementSetEditViewModel: RequirementSetEditViewModel, customerDeliveryRequirementsEditViewModel: DeliveryRequirementsViewModel) {
        this.customerInfo = customerInfo;
        this.primaryContact = primaryContact;
        this.billingContact = billingContact;
        this.shippingAddress = shippingAddress;
        this.billingAddress = billingAddress;
        this.additionalAddresses = displayAddresses;
        this.requirementSetEditViewModel = requirementSetEditViewModel;
        this.customerDeliveryRequirementsEditViewModel = customerDeliveryRequirementsEditViewModel;
    }

    private clone(): CustomerCreateEditViewModel {
        return new CustomerCreateEditViewModel(this.customerInfo, this.primaryContact, this.billingContact, this.shippingAddress, this.billingAddress, this.additionalAddresses, this.requirementSetEditViewModel, this.customerDeliveryRequirementsEditViewModel);
    }
    
    //region state methods
    
     //For the sake of maintaining consistency with this model, I'm adding methods on the view model to perform UI actions. 90% of the time, you never add logic in view models. View models exist as contracts between the front end and the back end (also referred to as DTOs), not as objects that perform operations.
    public addBlankDisplayAddressRow(): CustomerCreateEditViewModel {
        const result = this.clone();
        let id: number = Math.floor(Math.random() * 10000) + 1;
        
        //Here, I'm checking to see if the randomly generated id matches an existing id.
        while (this.additionalAddresses.some(x => x.id === id)) {
            id = Math.floor(Math.random() * 1000000);
        }

        result.additionalAddresses.push(new AddressViewModel(id, "", "", "", "", "", "", ""));
        return result;
    }
    
    public removeDisplayAddress(id: number): CustomerCreateEditViewModel {
        const result = this.clone();
        
        const index = result.additionalAddresses.findIndex(x => x.id === id);
        result.additionalAddresses.splice(index, 1);
        
        return result;
    }
    
    public updateAdditionalAddresses(additionalAddresses: AddressViewModel) {
        const result = this.clone();
        
        const displayAddressToUpdate = result.additionalAddresses.findIndex(x => x.id === additionalAddresses.id);
        
        result.additionalAddresses.splice(displayAddressToUpdate, 1, additionalAddresses);
        
        return result;
    }
    
    public updateCustomerInfo(customerInfo: CustomerInfoViewModel) {
        const result = this.clone();
        result.customerInfo = customerInfo;
        return result;
    }
    
    public updatePrimaryContact(primaryContact: ContactViewModel) {
        const result = this.clone();
        result.primaryContact = primaryContact;
        return result;
    }
    
    public updateBillingContact(billingContact: ContactViewModel) {
        const result = this.clone();
        result.billingContact = billingContact;
        return result;
    }
    
    public updateShippingAddress(shippingAddress: AddressViewModel) {
        const result = this.clone();
        result.shippingAddress = shippingAddress;
        return result;
    }
    
    public updateBillingAddress(billingAddress: AddressViewModel) {
        const result = this.clone();
        result.billingAddress = billingAddress;
        return result;
    }

    public updateDeliveryRequirements(deliveryRequirements: DeliveryRequirementsViewModel): CustomerCreateEditViewModel {
        const result = this.clone();
        result.customerDeliveryRequirementsEditViewModel = deliveryRequirements;
        return result;
    }
    
    public updateRequirementSet(requirementSet: RequirementSetEditViewModel): CustomerCreateEditViewModel {
        const result = this.clone();
        result.requirementSetEditViewModel = requirementSet;
        return result;
    }

//endregion
}