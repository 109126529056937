import {IAPIGateway} from "./IAPIGateway";
import {handleJsonResponse} from "../helperFunctions/handleJsonResponse";
import {handleBlobResponse} from "../helperFunctions/handleBlobResponse";

export class APIGateway implements IAPIGateway {
    public async get<T>(path: string, controller?: AbortSignal): Promise<T> {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: controller
        };

        return fetch(path, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }

    public async getFile(path: string): Promise<File | void> {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/octet-stream"
            }
        };

        const response = await fetch(path, requestOptions);

        const blob = await handleBlobResponse(response);

        const contentDisposition = response.headers.get('Content-Disposition');
        let filename: string = "";
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="([^"]+)"/);
            if (match && match.length > 1) {
                filename = match[1];
            }
        }

        if (!contentDisposition) {
            return;
        }

        return new File([blob], filename, {type: blob?.type});
    }

    public async post<T>(path: string, requestBody: object, controller?: AbortSignal): Promise<T> {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: controller,
            body: requestBody ? JSON.stringify(requestBody) : ""
        };

        return fetch(path, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }

    public async postFile<T>(path: string, file: File, signal: AbortSignal): Promise<T> {
        const formData = new FormData();
        formData.append('file', file);

        const requestOptions = {
            method: "POST",
            body: formData,
            signal: signal
        };

        return fetch(path, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }

    public async postFiles<T>(path: string, files: File[]): Promise<T> {
        const formData = new FormData();
        files.forEach((x) => {
            formData.append(`files`, x);
        });
        
        const requestOptions = {
            method: "POST",
            body: formData,
        };

        return fetch(path, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }

    public async putFile(path: string, file: File): Promise<void> {
        const formData = new FormData();
        formData.append('file', file);

        const requestOptions = {
            method: "PUT",
            body: formData
        };

        return fetch(path, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }

    public async put(path: string, requestBody?: object, controller?: AbortSignal): Promise<void> {
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: controller,
            body: requestBody ? JSON.stringify(requestBody) : ""
        };

        return fetch(path, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }

    public async delete(path: string, requestBody?: object): Promise<void> {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(requestBody)
        };

        return fetch(path, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            })
    }
}