import {IVPOGateway} from "./IVPOGateway";
import {CalculatePricingViewModel} from "../viewModels/CalculatePricingViewModel";
import {
    VariancePurchaseOrderCreateViewModel
} from "../viewModels/VariancePurchaseOrderCreateViewModel";
import {
    VariancePurchaseFinalizationEditViewModel
} from "../viewModels/VariancePurchaseOrderFinalizationEditViewModel";
import {handleJsonResponse} from "../../helperFunctions/handleJsonResponse";

export default class VPOGateway implements IVPOGateway {
    baseUrl: string = '/api/';

    ListLotsBySubdivision = async (subdivisionId: number, search: string, signal: AbortSignal) => {
        return await this.GetRequest(`subdivisions/${subdivisionId}/shippingLocation?search=${search}`, signal)
    }

    GetReasons = async (signal:AbortSignal) => {
        return await this.GetRequest(`variancePurchaseOrderReasons/list`, signal);
    }
    
    CalculatePrice = async (items: CalculatePricingViewModel[], customerId: number, signal:AbortSignal) => {
        return await this.PostRequest(`variancePurchaseOrder/calculatePrice/${customerId}`, items, signal)
    }

    CreateVariancePurchaseOrder = async (createVm: VariancePurchaseOrderCreateViewModel, signal: AbortSignal) => {
        return await this.PostRequest(`variancePurchaseOrder`, createVm,  signal)
    }

    GetVariancePurchaseOrder = async (vpoId: number, signal: AbortSignal) => {
        return await this.GetRequest(`variancePurchaseOrder/${vpoId}`, signal)
    }

    SubmitVariancePurchaseOrder = async (vpoId: number, finalizedVM: VariancePurchaseFinalizationEditViewModel, signal: AbortSignal) => {
        return await this.PutRequest(`variancePurchaseOrder/${vpoId}/finalize`, finalizedVM, signal)
    }

    GetContractorInformationByVpo = async (vpoId: number, signal: AbortSignal) => {
        return await this.GetRequest(`variancePurchaseOrder/${vpoId}/getContractorInformation`, signal)
    }

    GetShippingLocation = async (vpoId: number, signal: AbortSignal) => {
        return await this.GetRequest(`variancePurchaseOrder/${vpoId}/getShippingLocation`, signal)
    }

    GetLineItems = async (vpoId: number, signal: AbortSignal) => {
        return await this.GetRequest(`variancePurchaseOrder/${vpoId}/getLineItems`, signal)
    }

    SubmitInternalConfirmation = async (vpoId: number, requestObj: object, signal: AbortSignal) => {
        return await this.PutRequest(`variancePurchaseOrder/${vpoId}/internalConfirm`, requestObj, signal)
    }

    private async GetRequest(path: string, signal: AbortSignal) {
        const url = this.baseUrl + path;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            signal: signal
        };

        return this.Fetch(url, requestOptions)
    }

    private async PostRequest(path: string, requestObject: object, signal: AbortSignal, ) {
        const url = this.baseUrl + path;
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: requestObject ? JSON.stringify(requestObject) : "",
            signal: signal
        };

        return this.Fetch(url, requestOptions)
    }

    private async PutRequest(path: string, requestObject: object, signal: AbortSignal){
        const url = this.baseUrl + path;
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: requestObject ? JSON.stringify(requestObject) : "",
            signal: signal
        };
        
        return this.Fetch(url, requestOptions)
    }

    private async Fetch(url: string, requestOptions: object) {
        return fetch(url, requestOptions)
            .then(handleJsonResponse)
            .then((data) => {
                return data;
            }).catch((e: Error) => {
                throw new Error(e.message);
            });
    }
}
 