import {UserType} from "../UserTypeEnum";

export class User {
    public id: number;
    public firstName: string;
    public lastName: string;
    public userType: UserType;
    public primaryEmail: string;
    public secondaryEmail?: string;
    public phone?: string;
    constructor(id: number, firstName: string, lastName: string, userType: UserType, primaryEmail: string, secondaryEmail?: string, phone?: string) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.userType = userType;
        this.primaryEmail = primaryEmail;
        this.secondaryEmail = secondaryEmail;
        this.phone = phone;
    }
}