import {IOrderEntryBatchFormRepository} from "./IOrderEntryBatchFormRepository";
import {IOrderEntryBatchGateway} from "../gateways/IOrderEntryBatchGateway";
import {FileUpload} from "../../globalComponents/fileUpload/FileUpload";
import {ICustomerGateway} from "../../customerAggregate/gateways/ICustomerGateway";
import {IPlanGateway} from "../../planAggregate/gateways/IPlanGateway";
import {ISubdivisionGateway} from "../../subdivisionAggregate/gateway/ISubdivisionGateway";
import {OrderEntryBatchForm} from "../domain/OrderEntryBatchForm";
import {OrderEntryForm} from "../domain/OrderEntryForm";
import {TypeAheadField} from "../../globalComponents/typeAhead/TypeAheadField";
import {Lookup} from "../../globalComponents/lookup/Lookup";
import {
    IComponentManufacturerGateway
} from "../../componentManufacturerAggregate/gateways/IComponentManufacturerGateway";
import {PriorityOptions} from "../domain/Priority";
import {JobStatusOptions} from "../domain/JobStatus";
import {OrderEntryEmail} from "../domain/OrderEntryEmail";
import {garageOrientationOptions} from "../../planAggregate/GarageOrientation";
import {OrderEntryBatchFormBuilder} from "../builder/OrderEntryBatchFormBuilder";
import {OrderEntryEditViewModel} from "../viewModels/OrderEntryEditViewModel";
import {
    GARAGE_ORIENTATION_TOOLTIP_DISABLED,
    LOT_TOOLTIP_DISABLED,
    PLAN_TOOLTIP_DISABLED,
    SUBDIVISION_TOOLTIP_DISABLED
} from "../domain/OrderEntryTooltipMessages";
import {OrderEntryEmailAssociation} from "../domain/OrderEntryEmailAssociation";
import {OrderEntryEmailDetailViewModel} from "../viewModels/OrderEntryEmailDetailViewModel";
import {OrderEntryAssociationViewModel} from "../viewModels/OrderEntryAssociationViewModel";
import {OrderEntryDetailViewModel} from "../viewModels/OrderEntryDetailViewModel";
import {OrderEntryEmailAssociationsMode} from "../domain/OrderEntryEmailAssociationsMode";

export class OrderEntryBatchFormRepository implements IOrderEntryBatchFormRepository {
    private readonly _orderEntryBatchGateway: IOrderEntryBatchGateway;
    private readonly _customerGateway: ICustomerGateway;
    private readonly _plansGateway: IPlanGateway;
    private readonly _subdivisionGateway: ISubdivisionGateway;
    private readonly _componentManufacturerGateway: IComponentManufacturerGateway;

    constructor(orderEntryBatchGateway: IOrderEntryBatchGateway, customerGateway: ICustomerGateway, planGateway: IPlanGateway, subdivisionGateway: ISubdivisionGateway, componentManufacturerGateway: IComponentManufacturerGateway) {
        this._orderEntryBatchGateway = orderEntryBatchGateway;
        this._plansGateway = planGateway;
        this._customerGateway = customerGateway;
        this._subdivisionGateway = subdivisionGateway;
        this._componentManufacturerGateway = componentManufacturerGateway;
    }

    public async initializeSsReissue(fileUpload: FileUpload): Promise<string> {
        return await this._orderEntryBatchGateway.initializeNewSsReissue(fileUpload.files!);
    }

    /**
     * Gets orderEntryBatchForm data from backend and populates form components with the necessary options based on the form's current state
     * @param {string} batchId
     * @returns promise resolves with orderEntryBatchForm populated by backend data
     */
    public async getOrderEntryBatchForm(batchId: string): Promise<OrderEntryBatchForm> {
        const orderEntryBatchDetails = await this._orderEntryBatchGateway.listOrderEntriesByBatchId(batchId);
        const batchEmailVm = await this._orderEntryBatchGateway.listOrderEntryEmailsByBatchId(batchId);
        const customers = await this._customerGateway.GetCustomerList(true);
        const componentManufacturerOptions = await this._componentManufacturerGateway.listAllComponentManufacturers();
        
        const orderEntryBatch = OrderEntryBatchForm
            .initialize(batchId);
        const orderEntryBatchFormBuilder = new OrderEntryBatchFormBuilder(orderEntryBatch);

        //Populate typeahead options and values if those values exist from the view model
        const orderEntryForms = orderEntryBatchDetails.map(async (x: OrderEntryDetailViewModel, index) => {
            let subdivisions: Lookup[] | undefined;
            let plans: Lookup[] | undefined;
            let lots: Lookup[] | undefined;
            let customerLookup: Lookup | undefined;
            let subdivisionLookup: Lookup | undefined;
            let planLookup: Lookup | undefined;
            let lotLookup: Lookup | undefined;
            let garageOrientationLookup: Lookup | undefined;
            let componentManufacturerLookup: Lookup | undefined;
            let jobStatusLookup: Lookup | undefined;
            let priorityLookup: Lookup | undefined;

            /**
             * Utility function determines if a typeahead should be disabled
             * @param {number} parentId This should be the Id of the TypeAhead that the current field depends on. i.e. If Subdivision is dependendant on the Customer Field being completed before it is enabled, you should pass in the CustomerId, not the SubdivisionId.
             * @returns {boolean}
             */
            function generateIsDisabled(parentId: number = 0): boolean {
                return parentId === null || parentId === 0;
            }

            if (x.customerId && x.customerId !== 0) {
                customerLookup = await this._customerGateway.GetCustomerLookup(x.customerId);
            }

            if (x.customerId && x.customerId !== 0) {
                subdivisions = await this._customerGateway.ListSubdivisionLookupsByCustomerId(x.customerId, true);
                plans = await this._customerGateway.ListCustomerPlansLookup(x.customerId);
            }

            if (x.subdivisionId && x.subdivisionId !== 0) {
                lots = await this._subdivisionGateway.getSubdivisionLotsLookup(x.subdivisionId);
                subdivisionLookup = await this._subdivisionGateway.getSubdivisionLookupById(x.subdivisionId);
            }

            if (x.planId && x.planId !== 0) {
                planLookup = await this._plansGateway.getPlanLookupDetails(x.planId);
                const garageOrientationEnum = await this._plansGateway.getPlanDefaultGarageOrientation(x.planId);
                garageOrientationLookup = garageOrientationOptions.find(x => x.id === garageOrientationEnum);
            }

            if (x.lotId && x.subdivisionId && x.lotId !== 0) {
                lotLookup = await this._subdivisionGateway.getSubdivisionLotLookup(x.subdivisionId, x.lotId);
            }

            if (x.componentManufacturerId && x.componentManufacturerId !== 0) {
                componentManufacturerLookup = await this._componentManufacturerGateway.listComponentManufacturerLookup(x.componentManufacturerId);
            }

            if (x.jobStatus) {
                jobStatusLookup = JobStatusOptions.find(y => y.id === x.jobStatus);
            }

            if (x.priority) {
                priorityLookup = PriorityOptions.find(y => y.id === x.priority);
            }
            

            const customerTypeAheadField = new TypeAheadField("Customer", false, "Customers", customers, "", customerLookup);
            const subdivisionField = new TypeAheadField("Subdivision", generateIsDisabled(x.customerId), "Subdivisions", subdivisions, generateIsDisabled(x.customerId) ? SUBDIVISION_TOOLTIP_DISABLED : "", subdivisionLookup);
            const planField = new TypeAheadField("Plan", generateIsDisabled(x.subdivisionId), "Plans", plans, generateIsDisabled(x.subdivisionId) ? PLAN_TOOLTIP_DISABLED : "", planLookup);
            const lotField = new TypeAheadField("Lot", generateIsDisabled(x.subdivisionId), "Lots", lots, generateIsDisabled(x.subdivisionId) ? LOT_TOOLTIP_DISABLED : "", lotLookup);
            const garageOrientation = new TypeAheadField("Garage Orientation", generateIsDisabled(x.planId), "Garage Orientations", garageOrientationOptions, generateIsDisabled(x.subdivisionId) ? GARAGE_ORIENTATION_TOOLTIP_DISABLED : "", garageOrientationLookup);
            const componentManufacturerField = new TypeAheadField("Component Manufacturer", false, "Component Manufacturers", componentManufacturerOptions, "", componentManufacturerLookup);
            const dueDate =  x.dueDate ? new Date(x.dueDate) : new Date();
            const jobStatus = new TypeAheadField("Job Status", false, "Job Status", JobStatusOptions, "", jobStatusLookup);
            const priorityField = new TypeAheadField("Priority", false, "Priority", PriorityOptions, "", priorityLookup);
            const trussesIn = x.trussesIn ? new Date(x.trussesIn) : new Date();
            return new OrderEntryForm(x.id, x.orderEntryBatchId, x.status, customerTypeAheadField, subdivisionField, planField, lotField, garageOrientation, componentManufacturerField, dueDate, jobStatus, priorityField, trussesIn, false, `${index + 1}`, x.orderEntryEmailAssociations.map(x => new OrderEntryEmailAssociation(x.orderEntryId, x.orderEntryEmailId)));
        });

        const awaitedOrderEntryForms = await Promise.all(orderEntryForms);

        const batchEmails = batchEmailVm.map(x => {
            return this.associateBatchEmailsWithTheirRespectiveOrderEntries(awaitedOrderEntryForms, x);
        });

        orderEntryBatchFormBuilder.addOrderEntryForms(awaitedOrderEntryForms);
        
        orderEntryBatchFormBuilder.addBatchEmails(batchEmails);
        
        return orderEntryBatchFormBuilder;
    }

    //TODO: The if checks may not be needed. Revisit to see if they are needed
    private associateBatchEmailsWithTheirRespectiveOrderEntries(orderEntryForms: OrderEntryForm[], orderEntryEmailVm: OrderEntryEmailDetailViewModel) {
        //If the list of associations equals the number of order entries, then we can assume that it is associated with all orders
        if (orderEntryEmailVm.associatedMode === OrderEntryEmailAssociationsMode.AllOrders) {
            return new OrderEntryEmail(orderEntryEmailVm.orderEntryBatchId, orderEntryEmailVm.id, orderEntryEmailVm.title, orderEntryEmailVm.body, orderEntryEmailVm.attachmentFileNames, orderEntryForms.map(y => new OrderEntryEmailAssociation(y.id, orderEntryEmailVm.id, y.viewId, `Order ${y.viewId}`, y.hasAssociationWithEmail(y.id, orderEntryEmailVm.id))), orderEntryEmailVm.associatedMode, true);
        }
        //If the batch email is associated with only one order, it displays the text with the right order and view id
        else {
            const orderEntryEmailAssociations = orderEntryForms.map(x => {
                return new OrderEntryEmailAssociation(x.id, orderEntryEmailVm.id, x.viewId, `Order ${x.viewId}`, x.hasAssociationWithEmail(x.id, orderEntryEmailVm.id))
            });
            return new OrderEntryEmail(orderEntryEmailVm.orderEntryBatchId, orderEntryEmailVm.id, orderEntryEmailVm.title, orderEntryEmailVm.body, orderEntryEmailVm.attachmentFileNames, orderEntryEmailAssociations, orderEntryEmailVm.associatedMode, false);
        }
    }
    
    public async addEmailsToOrderEntryBatch(orderEntryBatchId: string, newOrderEntryEmails: File[]) {
        return await this._orderEntryBatchGateway.addEmailFilesToOrderEntryBatch(orderEntryBatchId, newOrderEntryEmails);
    }

    public async updateOrderEntry(orderEntryBatchId: string, orderEntryForm: OrderEntryForm) {
        const viewModel = new OrderEntryEditViewModel(orderEntryForm.customerField.value?.id!, orderEntryForm.subdivisionField.value?.id!, orderEntryForm.planField.value?.id!, orderEntryForm.lotField.value?.id!, orderEntryForm.trussesIn, orderEntryForm.garageOrientationField.value?.id, orderEntryForm.componentManufacturer.value?.id, orderEntryForm.dueDate, orderEntryForm.jobStatus.value?.id, orderEntryForm.priority.value?.id);
        return await this._orderEntryBatchGateway.updateOrderEntry(orderEntryBatchId, orderEntryForm.id, viewModel);
    }

    public async addNewOrderEntry(orderEntryBatchId: string) {
        return await this._orderEntryBatchGateway.addNewOrderEntry(orderEntryBatchId);
    }

    public async addAndDuplicateOrderEntry(orderEntryBatchId: string) {
        return await this._orderEntryBatchGateway.addAndDuplicateOrderEntry(orderEntryBatchId);
    }

    public async determineAssociationStatusOfOrderEntryEmail(orderEntryBatchId: string, orderEntryEmailId: string, orderEntryId: string, isSelected: boolean): Promise<void> {
        const orderEntryAssociationViewModel = new OrderEntryAssociationViewModel(orderEntryBatchId, orderEntryId, orderEntryEmailId);
        if(isSelected) {
            return await this._orderEntryBatchGateway.associateOrderEntryEmailWithOrderEntries(orderEntryBatchId, orderEntryAssociationViewModel);
        } else {
            return await this._orderEntryBatchGateway.unAssociateOrderEntryWithOrderEntryEmail(orderEntryBatchId, orderEntryEmailId, orderEntryId);
        }
    }
    
    public async associateOrderEntryEmailWithAllOrderEntries(orderEntryBatchId: string, orderEntryEmailId: string) {
        return this._orderEntryBatchGateway.associateOrderEntryEmailWithAllOrderEntriesInOrderEntryBatch(orderEntryBatchId, orderEntryEmailId);
    }
    
    public async unAssociateOrderEntryEmailWithAllOrderEntries(orderEntryBatchId: string, orderEntryEmailId: string) {
        return this._orderEntryBatchGateway.unAssociateOrderEntryEmailWithAllOrderEntriesInOrderEntryBatch(orderEntryBatchId, orderEntryEmailId);
    }
    
    public async determineAssociationStatusOfAllOrderEntryEmails(orderEntryBatchId: string, orderEntryEmailId: string, isAssociated: boolean): Promise<void> {
        if(isAssociated) {
            return await this._orderEntryBatchGateway.associateOrderEntryEmailWithAllOrderEntriesInOrderEntryBatch(orderEntryBatchId, orderEntryEmailId);
        } else {
            return await this._orderEntryBatchGateway.unAssociateOrderEntryEmailWithAllOrderEntriesInOrderEntryBatch(orderEntryBatchId, orderEntryEmailId);
        }
    }
    
    public async removeOrderEntryEmailFromOrderEntryBatch(orderEntryBatchId: string, orderEntryEmailId: string): Promise<void> {
        return await this._orderEntryBatchGateway.removeOrderEntryEmailFromOrderEntryBatch(orderEntryBatchId, orderEntryEmailId);
    }


    public async addAndDuplicateOrderEntryWithCustomer(orderEntryBatchId: string) {
        return await this._orderEntryBatchGateway.addAndDuplicateOrderEntryWithCustomer(orderEntryBatchId);
    }
    
    public async removeOrderEntryFromBatch(orderEntryBatchId: string, orderEntryId: string): Promise<void> {
        return await this._orderEntryBatchGateway.removeOrderEntryFromOrderEntryBatch(orderEntryBatchId, orderEntryId);
    }
    
    public async submitOrderEntryBatch(orderEntryBatchId: string) {
        return await this._orderEntryBatchGateway.submitOrderEntryBatch(orderEntryBatchId);
    }
}