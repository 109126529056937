import {Lookup} from "../lookup/Lookup";
import {LookupViewModel} from "../../infrastructure/lookup/LookupViewModel";
import {InheritedFieldChangeAlert} from "../../valueObjects/InheritedFieldChangeAlert";

export class TypeAheadField {
    private _label: string;
    private _isDisabled: boolean;
    private _placeholderText: string;
    private _options: Lookup[];
    private _value?: Lookup;
    private _tooltipMessage: string;
    private _hasInheritedFields: boolean;
    private _inheritedFieldChangeAlert: InheritedFieldChangeAlert<Lookup>;
    private _searchResult: string;

    constructor(label: string, isDisabled: boolean, placeholderText: string,  options: Lookup[] = [], tooltipMessage: string = "", value?: Lookup, hasInheritedField: boolean = false, searchResult: string = "", inheritedFieldChangeAlert: InheritedFieldChangeAlert<Lookup> = new InheritedFieldChangeAlert<Lookup>(false, new Lookup(0, ""))) {
        this._label = label;
        this._isDisabled = isDisabled;
        this._value = value;
        this._placeholderText = placeholderText;
        this._options = options;
        this._tooltipMessage = tooltipMessage;
        this._searchResult = searchResult;
        this._hasInheritedFields = hasInheritedField;
        this._inheritedFieldChangeAlert = inheritedFieldChangeAlert;
    }
    
    private clone(): TypeAheadField {
        return new TypeAheadField(this._label, this._isDisabled, this._placeholderText, this._options, this._tooltipMessage, this._value, this._hasInheritedFields, this._searchResult, this._inheritedFieldChangeAlert);
    }
    
    //region state methods
    
    public openInheritedFieldAlert() {
        const result = this.clone();
        result._inheritedFieldChangeAlert = new InheritedFieldChangeAlert(true, this._inheritedFieldChangeAlert.fieldToBeChanged);
        return result;
    }
    
    public updateLabel(label: string): TypeAheadField {
        const result = this.clone();
        result._label = label;
        return result;
    }
    
    public enable(): TypeAheadField {
        const result = this.clone();
        result._isDisabled = false;
        return result;
    }
    
    public updatePlaceholderText(placeholderText: string): TypeAheadField {
        const result = this.clone();
        result._placeholderText = placeholderText;
        return result;
    }
    
    public updateOptions(options: Lookup[]): TypeAheadField {
        const result = this.clone();
        result._options = options;
        return result;
    }
    
    public updateTooltipMessage(tooltipMessage: string): TypeAheadField {
        const result = this.clone();
        result._tooltipMessage = tooltipMessage;
        return result;
    }
    
    public updateValue(value: Lookup): TypeAheadField {
        const result = this.clone();
        result._value = value;
        return result;
    }
    
    public updateSearchResult(searchResult: string): TypeAheadField {
        const result = this.clone();
        result._searchResult = searchResult;
        return result;
    }
    
    //endregion
    
    
    //region getters
    get label(): string {
        return this._label;
    }

    get inheritedFieldChangeAlert(): InheritedFieldChangeAlert<Lookup> {
        return this._inheritedFieldChangeAlert;
    }

    get searchResult(): string {
        return this._searchResult;
    }

    get value(): Lookup | undefined {
        return this._value;
    }

    get isDisabled(): boolean {
        return this._isDisabled;
    }

    get placeholderText(): string {
        return this._placeholderText;
    }

    get options(): LookupViewModel[] {
        return this._options;
    }

    get tooltipMessage(): string | undefined {
        return this._tooltipMessage;
    }

    get hasInheritedFields(): boolean {
        return this._hasInheritedFields;
    }

//endregion
    
    public getFilteredList() {
        if(this._searchResult.length > 0) {
            return this._options.filter(x => {
                return x.name.toLowerCase().includes(this._searchResult.toLowerCase());
            })
        } else {
            return this._options;
        }
    }
}