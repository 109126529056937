import {ISpecification} from "./ISpecification";
import {SpecificationResult} from "./SpecificationResult";

export class CountySpecification implements ISpecification<string> {
    public isSatisfiedBy(value: string): SpecificationResult {
        if(/\d/.test(value)) {
            return new SpecificationResult(false, "Counties cannot have numbers as inputs.");
        } else {
            return new SpecificationResult(true);
        }
    }
}