import {Lookup} from "../../lookup/Lookup";

export class TableColumnArranger {
    private _isOpen: boolean;
    private _headers: Lookup[];
    
    constructor(items: Lookup[] = [], isOpen: boolean = false) {
        this._headers = items;
        this._isOpen = isOpen;
    }
    
    private clone(): TableColumnArranger {
        const newItems = [...this._headers];
        return new TableColumnArranger(newItems, this._isOpen);
    }
    
    //region state methods
    public toggleVisibility(): TableColumnArranger {
        const result = this.clone();
        result._isOpen = !result._isOpen;
        return result;
    }
    
    // The 'dragId' refers to the ID of the item that is currently being dragged.
    // The 'hoverId' refers to the ID of the item that is being hovered over by a dragged item.
    public updateOrder(dragIndex: number, hoverIndex: number): TableColumnArranger {
        const result = this.clone();
        const [movedItem] = result._headers.splice(dragIndex, 1);
        result._headers.splice(hoverIndex, 0, movedItem);
        return result;
    }
    
    //endregion

    //region getters
    get isOpen(): boolean {
        return this._isOpen;
    }

    get headers(): Lookup[] {
        return this._headers;
    }
    //endregion
}