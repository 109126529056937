import {ISubdivisionGateway} from "../gateway/ISubdivisionGateway";
import {SubdivisionForm} from "../domain/SubdivisionForm";
import {SubdivisionEditViewModel} from "../viewModels/SubdivisionEditViewModel";
import {SubdivisionAdditionalInformationViewModel} from "../viewModels/SubdivisionAdditionalInformationViewModel";
import {DateFormatter} from "../../utilities/DateFormatter";
import {ISubdivisionRepo} from "./ISubdivisionRepo";
import {SubdivisionCustomerLink} from "../valueObjects/subdivisionCustomer/SubdivisionCustomerLink";
import {ICustomerGateway} from "../../customerAggregate/gateways/ICustomerGateway";
import {ITrussManufacturerGateway} from "../../trussManufacturerAggregate/gateway/ITrussManufacturerGateway";
import {SubdivisionAdditionalInformation} from "../valueObjects/SubdivisionAdditionalInformation";
import {SubdivisionBuildingDepartment} from "../valueObjects/subdivisionCustomer/SubdivisionBuildingDepartment";
import {
    BuildingDepartmentListViewModel
} from "../../buildingDepartmentAggregate/viewModels/BuildingDepartmentListViewModel";
import {SubdivisionCustomerInformationEditViewModel} from "../viewModels/SubdivisionCustomerInformationEditViewModel";
import {LotForm} from "../../lotAggregate/domain/LotForm";
import {LotEditViewModel} from "../../lotAggregate/viewModels/LotEditViewModel";

export class SubdivisionRepo implements ISubdivisionRepo {
    private readonly _subdivisionGateway: ISubdivisionGateway;
    private readonly _customerGateway: ICustomerGateway;
    private readonly _trussManufacturerGateway: ITrussManufacturerGateway;

    constructor(subdivisionGateway: ISubdivisionGateway, customerGateway: ICustomerGateway, trussManufacturerGateway: ITrussManufacturerGateway) {
        this._subdivisionGateway = subdivisionGateway;
        this._customerGateway = customerGateway;
        this._trussManufacturerGateway = trussManufacturerGateway;
    }

    public async add(subdivision: SubdivisionForm): Promise<number | void> {
        if (subdivision.name === "" || !subdivision.name) {
            throw new Error("Subdivision Name is required.");
        }

        if (subdivision.subdivisionCustomerLink.some(x => x.customerName === "" || !x.customerName)) {
            throw new Error("Customer Name is required.");
        }

        if (subdivision.subdivisionCustomerLink.some(x => x.trussManufacturerName === "" || !x.trussManufacturerName)) {
            throw new Error("Truss Manufacturer Name is required.")
        }

        const subdivisionEditViewModel = new SubdivisionEditViewModel(
            subdivision.id,
            subdivision.customerId,
            subdivision.name,
            subdivision.subdivisionCustomerLink.map(x => new SubdivisionCustomerInformationEditViewModel(
                    x.customerId,
                    x.trussManufacturerId,
                    x.communitySpecificInfo,
                    x.activePlans.map(x => x.id)
                )
            ),
            subdivision.windSpeed,
            subdivision.windExposure,
            subdivision.seismicCategory,
            new SubdivisionAdditionalInformationViewModel(
                subdivision.subdivisionAdditionalInformation?.subdivisionBuildingDepartments?.map(x => x.id),
                DateFormatter.formatDateStringToDate(subdivision.subdivisionAdditionalInformation?.dateAdded ?? ""),
                subdivision.subdivisionAdditionalInformation?.subdivisionStatus,
                subdivision.subdivisionAdditionalInformation?.subdivisionPhase,
            )
        )


        if (subdivision.subdivisionAdditionalInformation?.platMap) {
            const subdivisionId = await this._subdivisionGateway.addSubdivision(subdivisionEditViewModel);
            return this._subdivisionGateway.addSubdivisionPlatMap(subdivisionId, subdivision.subdivisionAdditionalInformation.platMap);
        } else {
            return await this._subdivisionGateway.addSubdivision(subdivisionEditViewModel);
        }
    }

    public addLotToSubdivision(subdivisionId: number, lot: LotForm) {
        if (lot.lotName.prefix === "") {
            throw new Error("Prefix is required.");
        }

        if (lot.lotName.range === "") {
            throw new Error("Lot Range is required.");
        }

        if (lot.customer.name === "") {
            throw new Error("Customer is required.");
        }

        if (lot.trussManufacturer.name === "") {
            throw new Error("Truss Manufacturer is required.");
        }


        const lotEditViewModel = new LotEditViewModel(
            lot.id ?? 0,
            lot.lotName.prefix,
            lot.lotName.range,
            lot.customer.id,
            lot.trussManufacturer.id,
            lot.lotName.suffix,
            lot.street,
            lot.city,
            lot.zip,
            lot.country,
            lot.state,
            lot.windSpeed,
            lot.windExposure
        );

        if (!lot.isSatisfiedBy(lot).isSatisfied) {
            throw new Error(lot.isSatisfiedBy(lot).errorReason)
        }

        return this._subdivisionGateway.addLotToSubdivision(subdivisionId, lotEditViewModel);
    }

    public async store(entityId: number, entity: SubdivisionForm): Promise<number | void> {

        if (entity.name === "" || !entity.name) {
            throw new Error("Subdivision Name is required.");
        }

        if (entity.subdivisionCustomerLink.some(x => x.customerName === "" || !x.customerName)) {
            throw new Error("Customer Name is required.");
        }

        if (entity.subdivisionCustomerLink.some(x => x.trussManufacturerName === "" || !x.trussManufacturerName)) {
            throw new Error("Truss Manufacturer Name is required.")
        }

        const subdivisionEditViewModel = new SubdivisionEditViewModel(
            entity.id,
            entity.customerId,
            entity.name,
            entity.subdivisionCustomerLink.map(x => new SubdivisionCustomerInformationEditViewModel(x.customerId, x.trussManufacturerId, x.communitySpecificInfo, x.activePlans.map(y => y.id))),
            entity.windSpeed,
            entity.windExposure,
            entity.seismicCategory,
            new SubdivisionAdditionalInformationViewModel(
                entity.subdivisionAdditionalInformation?.subdivisionBuildingDepartments?.map(x => x.id),
                DateFormatter.formatDateStringToDate(typeof (entity.subdivisionAdditionalInformation?.dateAdded) === "string" ? entity.subdivisionAdditionalInformation?.dateAdded : ""),
                entity.subdivisionAdditionalInformation?.subdivisionStatus,
                entity.subdivisionAdditionalInformation?.subdivisionPhase,
            )
        );

        if (entity.subdivisionAdditionalInformation?.platMap) {
            await this._subdivisionGateway.updateSubdivision(entityId, subdivisionEditViewModel);
            return this._subdivisionGateway.addSubdivisionPlatMap(entity.id, entity.subdivisionAdditionalInformation.platMap);
        }
        return this._subdivisionGateway.updateSubdivision(entityId, subdivisionEditViewModel);
    }

    public async getById(subdivisionId: number): Promise<SubdivisionForm> {
        const result = await this._subdivisionGateway.getSubdivisionById(subdivisionId);
        const dateAdded = new Date(result.additionalInformation?.dateAdded ?? new Date());
        const subdivisionCustomerLinks = await Promise.all(result.subdivisionCustomerInformation?.map(async (x, index) => {
            const customerLookup = await this._customerGateway.GetCustomerLookup(x.customerId);
            const trussManufacturerLookup = await this._trussManufacturerGateway.GetTrussManufacturerByIdLookup(x.trussManufacturerId);
            const customerPlansLookup = await this._customerGateway.ListCustomerPlansLookup(x.customerId);
            return new SubdivisionCustomerLink(index, customerLookup.id, customerLookup.name, trussManufacturerLookup.id, trussManufacturerLookup.name, customerPlansLookup, x.communitySpecificInfo);
        }));
        const subdivisionBuildingDepartments: BuildingDepartmentListViewModel[] = await this._subdivisionGateway.getSubdivisionBuildingDepartments(subdivisionId);

        const platMap = await this._subdivisionGateway.getSubdivisionPlatMap(subdivisionId);

        const additionalInformation = new SubdivisionAdditionalInformation(subdivisionBuildingDepartments.map(x => new SubdivisionBuildingDepartment(x.id, x.name)), DateFormatter.formatDateObjectToDateString(dateAdded), result.additionalInformation?.subdivisionStatus, result.additionalInformation?.subdivisionPhase, platMap ?? undefined);


        return new SubdivisionForm(result.customerId, result.id, result.name, 0, subdivisionCustomerLinks, result.windSpeed, result.windExposure, result.seismicCategory, additionalInformation);
    }
}