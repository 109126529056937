export class BuildingDepartmentLocation {
    public city: string;
    public state: string;
    public county: string;

    constructor(city: string, state: string, county: string) {
        this.city = city;
        this.state = state;
        this.county = county;
    }
}