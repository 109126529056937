import {CustomerSubdivisionTableData} from "./CustomerSubdivisionTableData";
import {TableColumnArranger} from "../../globalComponents/Table/columnRearrange/TableColumnArranger";
import {TableTitle} from "../../globalComponents/Table/TableTitle";

export class CustomerSubdivisionTable {
    private _tableTitles: TableTitle[];
    private _customerId: number;
    private _subdivisionTableData: CustomerSubdivisionTableData[];
    private _isActionPanelOpen: boolean;
    private _tableColumnArranger: TableColumnArranger;
    private _isLoading: boolean;


    constructor(tableTitles: TableTitle[] = [], customerId: number, subdivisionTableData: CustomerSubdivisionTableData[], isActionPanelOpen: boolean = false, tableColumnArranger: TableColumnArranger, isLoading: boolean = false) {
        this._tableTitles = tableTitles;
        this._customerId = customerId;
        this._subdivisionTableData = subdivisionTableData;
        this._isActionPanelOpen = isActionPanelOpen;
        this._tableColumnArranger = tableColumnArranger;
        this._isLoading = isLoading;
    }

    private clone() {
        return new CustomerSubdivisionTable(this._tableTitles, this._customerId, this._subdivisionTableData, this._isActionPanelOpen, this._tableColumnArranger, this._isLoading);
    }

    //region state methods
    public closeActionPanel() {
        const result = this.clone();
        result._isActionPanelOpen = false;
        return result;
    }

    public toggleIsLoading() {
        const result = this.clone();
        result._isLoading = !result._isLoading;
        return result;
    }
    
    public reorderColumnsByIds(columnIds: number[]): CustomerSubdivisionTable {
        const result = this.clone();
        //First Item in array (Subdivision Name) can't be rearranged.
        const firstItem = result._tableTitles[0];
        let slicedTitles = result._tableTitles.slice(1);

        //Sort array, excluding the first item
        slicedTitles = columnIds?.map(id => {
            return slicedTitles.find(x => x.id === id)!;
        });

        //Add the first item back to the array
        //Array check here is for moq.ts. I believe it might struggle to recognize the spread operator.
        if (Array.isArray(slicedTitles)) {
            result._tableTitles = [firstItem, ...slicedTitles];
        }
        return result;
    }

    public openActionPanel() {
        const result = this.clone();
        result._isActionPanelOpen = true;
        return result;
    }

    public updateTableColumnArranger(tableColumnArranger: TableColumnArranger) {
        const result = this.clone();
        result._tableColumnArranger = tableColumnArranger;
        return result;
    }
    
    public hasUserSettings(tableTitles: TableTitle[], tableSettings: number[]) {
        return tableTitles.slice(1).length === tableSettings?.length;
    }

    //endregion

    //region getters
    get customerId(): number {
        return this._customerId;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get tableTitles(): TableTitle[] {
        return this._tableTitles;
    }

    get isActionPanelOpen(): boolean {
        return this._isActionPanelOpen;
    }

    get subdivisionTableData(): CustomerSubdivisionTableData[] {
        return this._subdivisionTableData;
    }

    get tableColumnArranger(): TableColumnArranger {
        return this._tableColumnArranger;
    }

//endregion
}