import {useEffect, useState} from "react";
import {IThemes, Theme} from "./types";
import {BeLit} from "./BeLit";
import {TrueHouse} from "./TrueHouse";
import {applyTheme} from "./utils";
export interface IUseThemeOptions {
    theme: Theme;
    toggleTheme: (theme: Theme) => void;
    primaryLogo: string;
    secondaryLogo: string;
    verticalNav: string;
    applyTheme: (theme: string) => void;
    themeName: string;
}
export const themes: IThemes = {
    BeLit,
    TrueHouse
};


export const useTheme = (initialTheme: Theme): IUseThemeOptions => {
    const [theme, setTheme] = useState<Theme>(initialTheme);
    const [primaryLogo, setPrimaryLogo] = useState<IUseThemeOptions["primaryLogo"]>(themes[theme].logos.primary);
    const [secondaryLogo, setSecondaryLogo] = useState<IUseThemeOptions["secondaryLogo"]>(themes[theme].logos.secondary);
    const [verticalLogo] = useState<IUseThemeOptions["verticalNav"]>(themes[theme].logos.verticalNav)
    const [themeName, setThemeName] = useState<IUseThemeOptions["themeName"]>(themes[theme].name);
    
    const toggleTheme = (theme: Theme) => {
        setTheme(theme)
        if (theme) {
            const selectedTheme = themes[theme]
            const {primary, secondary} = selectedTheme.logos
            setPrimaryLogo(primary)
            setSecondaryLogo(secondary)
            setThemeName(selectedTheme.name)
        }
        applyTheme(theme)
    }
    useEffect(() => {
        applyTheme(theme)
    },[])
    useEffect(() => {
        setTheme(initialTheme)
        applyTheme(initialTheme)
    }, [initialTheme])
    
    return {theme, toggleTheme, primaryLogo, secondaryLogo, applyTheme, themeName, verticalNav: verticalLogo}
}